import { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import AttachmentDetails from "../AttachmentDetails";
import ContentLoader from "../ContentLoader";
import MediaItem from "../MediaItem";
import useLoading from "../../../hooks/useLoading";
import useMedia from "../../../hooks/useMedia";
import { greyVariations } from "../../../constants/colors";
import { useSelector } from "react-redux";
import { ListingByAccountVariables } from "../../../models/app";

interface Props {
  multiple: boolean;
  title: any;
}

const MediaFiles: React.FC<Props> = ({ multiple, title }) => {
  const { loading, changeLoading } = useLoading();
  const accountsSelected = useSelector((state: any) => state.accounts.selected);
  const mediaLibraryListing = useSelector(
    (state: any) => state.mediaLibrary.listing
  );
  const mediaLibrarySelected = useSelector(
    (state: any) => state.mediaLibrary.selected
  );
  const {
    mediaLibraryFetch,
    mediaLibraryChangeListing,
    mediaLibraryChangeSelected,
  } = useMedia("mediaLibrary", "media");

  useEffect(() => {
    changeLoading(true);

    const fetch = async () => {
      const params: ListingByAccountVariables = {
        searchText: "",
        startIndex: 0,
        limit: 1000,
        accountID: accountsSelected.id,
      };

      const data = await mediaLibraryFetch(params);
      if (title === "PDF") {
        let pdfList = data.filter((media: any) => {
          return (
            media.filename.includes("pdf") && !media.filename.includes("icon")
          );
        });
        mediaLibraryChangeListing(pdfList);
      } else {
        let pdfList = data.filter((media: any) => {
          return !media.filename.includes("pdf");
        });
        mediaLibraryChangeListing(pdfList);
      }
      changeLoading(false);
    };

    fetch();

    // eslint-disable-next-line
  }, []);

  const handleSelect = (event: React.MouseEvent<unknown>, id: string) => {
    const newSelected = multiple ? new Set(mediaLibrarySelected) : new Set();

    newSelected.add(id);

    mediaLibraryChangeSelected(newSelected);
  };

  if (loading) return <ContentLoader />;

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        lg={9}
        sx={{
          p: 2,
          width: "100%",
          height: "calc(94vh - 169px)",
          overflowY: "auto",
        }}
      >
        <Box>
          {mediaLibraryListing.map((media: any) => (
            <>
              <MediaItem
                key={media.id}
                isSelected={mediaLibrarySelected.has(media.id)}
                file={media}
                onClick={(event: React.MouseEvent<unknown>) =>
                  handleSelect(event, media.id)
                }
              />
            </>
          ))}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          borderLeft: 1,
          borderColor: "divider",
          height: "calc(94vh - 169px)",
          background: (theme) => greyVariations(theme, 100),
          p: 2,
          overflowY: "auto",
        }}
      >
        <AttachmentDetails />
      </Grid>
    </Grid>
  );
};

export default MediaFiles;
