import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

import ImagePicker from "../ImagePicker";
import { openModal, closeModal } from "../../../store/ducks/app";

import { commonWhite } from "../../../constants/colors";
import ImageContainer from "../ImageContainer";
import useMedia from "../../../hooks/useMedia";
import React, { useState } from "react";
import useApp from "../../../hooks/useApp";
import { Pages } from "../../../constants/enums";
import { Box, Grid, Typography } from "@mui/material";


interface Props {
  label: string;
  attachment: any;
  changeAttachment: any;
  resource?:any;
  slug?:any;
  value?:any;
}

const SetImage: React.FC<Props> = ({ label, attachment, changeAttachment ,resource,slug,value}) => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(
    (state: any) => state.app.userPermissions
  );
  const { hasPermission } = useApp();
  const { mediaLibraryChangeSelected } = useMedia("mediaLibrary", "media");

  const handleEditImage = () => {
    if (hasPermission(userPermissions, "settings")) {
      dispatch(
        openModal(
          <ImagePicker
            title={label}
            attachment={attachment}
            changeAttachment={(attachment: any) => changeAttachment(attachment)}
            closeModal={() => {
              mediaLibraryChangeSelected(new Set());
              dispatch(closeModal());
            }}
            resource={resource}
            slug={slug}
          />
        )
      );
    }
  };

  return (
    <>
      {attachment &&  slug!==Pages.MENU_ITEMS && slug!==Pages.MAIN_CATEGORIES  && slug!==Pages.SUB_CATEGORIES && slug!==Pages.CATEGORIES?(
        <ImageContainer
          src={attachment.fileUrl}
          thumb={attachment.fileUrl}
          alt={attachment.alternativeText}
        />
      ):((attachment&&label!=="PDF")?
      (  <ImageContainer
        src={attachment}
        thumb={attachment}
        alt={""}
      />):((attachment&&label==="PDF")?(
        <Grid container>
           <Grid  item xs={2} >
        <ImageContainer
          src={"1698512909567-PDF_file_icon.svg.png"}
          thumb={"1698512909567-PDF_file_icon.svg.png"}
          alt={""}
        />
      </Grid>
      <Grid  item xs={10} container justifyContent={"start"} alignContent={"center"}>
      <Typography 
      id="thumbnail-box"
      sx={{
        paddingLeft:"1rem",
        textAlign:"center"
      }}>
        {(attachment)}</Typography>
        </Grid>
        </Grid>):(""))
      )}
      <Button
        size="small"
        fullWidth
        disableRipple
        disableFocusRipple
        disableTouchRipple
        sx={{
          textDecoration: hasPermission(userPermissions, "settings")
            ? "underline"
            : "none",
          textTransform: "capitalize",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: (theme) => commonWhite(theme),
          borderTop: "none",
          p: 1,
          cursor: hasPermission(userPermissions, "settings")
            ? "pointer"
            : "initial",
          "&:hover": {
            background: "transparent",
            textDecoration: hasPermission(userPermissions, "settings")
              ? "underline"
              : "none",
          },
        }}
        onClick={handleEditImage}
      >
        {label}
      </Button>
    </>
  );
};

export default SetImage;
