import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SetImage from "../SetImage";

interface Props {
  label: string;
  attachment: any;
  changeAttachment: any;
  resource?:any;
  slug?:any;
  value?:any;
}

const FeaturedImage: React.FC<Props> = ({
  label,
  attachment,
  changeAttachment,
  resource,
  slug,
  value,
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, .05)"
              : "rgba(0, 0, 0, .03)",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderBottom: "none",
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{label}</Typography>
      </Box>
      <Paper elevation={0}>
        <SetImage
          value={value}
          label={label}
          attachment={attachment}
          changeAttachment={changeAttachment}
          resource={resource}
          slug={slug}
        />
      </Paper>
    </Box>
  );
};

export default FeaturedImage;
