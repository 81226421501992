import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import useApp from "../../../hooks/useApp";
import { useEffect, useState } from "react";
import ButtonLoader from "../ButtonLoader";
import useUpdating from "../../../hooks/useUpdating";
import useMenuItem from "../../../hooks/useMenuItem";
import useChoiceGroup from "../../../hooks/useChoiceGroup";
import useLoading from "../../../hooks/useLoading";
import ContentLoader from "../ContentLoader";
import { Choice, ChoiceGroups } from "../../../models";
import { DataStore } from "aws-amplify";
import { useSelector } from "react-redux";

interface Props {
  menuItemId: string;
  choiceGroupId: string;
}

interface CreateChoiceGroupInput {
  name: string;
  required?: boolean;
  maxNumberOfChoices?: number;
  minNumberOfChoices?: number;
  precedence?: string;
  choices?: string[] | any[];
}

interface UpdateChoiceGroupInput {
  name?: string;
  required?: boolean;
  maxNumberOfChoices?: number;
  minNumberOfChoices?: number;
  precedence?: string;
  choices?: string[] | any[];
}

const ChoiceGroupModal: React.FC<Props> = ({ menuItemId, choiceGroupId }) => {
  const { loading, changeLoading } = useLoading();
  const session = useSelector((state: any) => state.app.session);
  const [choiceListing, setChoiceListing] = useState<Choice[]>([]);
  const [name, setName] = useState("");
  const [required, setRequired] = useState(false);
  const [maxNumberOfChoices, setMaxNumberOfChoices] = useState(0);
  const [minNumberOfChoices, setMinNumberOfChoices] = useState(0);
  const [precedence, setPrecedence] = useState("");
  const [choices, setChoices] = useState<string[] | any[]>([]);
  const { hideDeletePopup, showError, showSecondaryPopup } = useApp();
  const { updating, changeUpdating } = useUpdating();
  const { menuItemsUpdate } = useMenuItem("menuItems", "menuItem");
  const {
    choiceGroupsGet,
    choiceGroupsGetChoices,
    choiceGroupsUpdate,
    choiceGroupsCreate,
    choiceGroupsTrashChoice,
  } = useChoiceGroup("choiceGroups", "choiceGroup");

  useEffect(() => {
    const fetch = async () => {
      const choiceGroup: ChoiceGroups = await choiceGroupsGet({
        id: choiceGroupId,
      });
      setName(choiceGroup.name);
      setRequired(choiceGroup.required ? choiceGroup.required : false);
      setMaxNumberOfChoices(
        choiceGroup.maxNumberOfChoices ? choiceGroup.maxNumberOfChoices : 0
      );
      setMinNumberOfChoices(
        choiceGroup.minNumberOfChoices ? choiceGroup.minNumberOfChoices : 0
      );
      setPrecedence(choiceGroup.precedence ? choiceGroup.precedence : "");
      setChoices(choiceGroup.choices ? choiceGroup.choices : []);
      changeLoading(false);
    };
    choiceGroupId.length > 0 ? fetch() : changeLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchGroupChoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = DataStore.observe(ChoiceGroups as any).subscribe(() =>
      fetchGroupChoices()
    );

    return () => subscription.unsubscribe();
  });

  useEffect(() => {
    const subscription = DataStore.observe(Choice as any).subscribe(() =>
      fetchGroupChoices()
    );

    return () => subscription.unsubscribe();
  });

  const fetchGroupChoices = async () => {
    const data = await choiceGroupsGetChoices(choiceGroupId);
    setChoiceListing(data);
    changeLoading(false);
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.target.value);
  };

  const handleRequiredChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRequired(!required);
  };

  const handleMinChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMinNumberOfChoices(parseInt(event.target.value));
  };

  const handleMaxChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMaxNumberOfChoices(parseInt(event.target.value));
  };

  const handlePrecedenceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPrecedence(event.target.value);
  };

  const create = async () => {
    changeUpdating(true);

    if (name === "") {
      changeUpdating(false);
      return showError("Name is required");
    }

    const input: CreateChoiceGroupInput = {
      name,
      required,
      maxNumberOfChoices,
      minNumberOfChoices,
      precedence,
      choices,
    };

    const choiceGroupEntry = await choiceGroupsCreate({
      userID: session.sub,
      userName: session.name,
      data: input,
    });
    await menuItemsUpdate({
      id: menuItemId,
      data: {
        choiceGroups: [choiceGroupEntry.id],
      },
    });

    changeUpdating(false);
    hideDeletePopup();
  };

  const update = async () => {
    changeUpdating(true);

    const updateInput: UpdateChoiceGroupInput = {
      name,
      required,
      maxNumberOfChoices,
      minNumberOfChoices,
      precedence,
      choices,
    };
    await choiceGroupsUpdate({ id: choiceGroupId, data: updateInput });
    await menuItemsUpdate({
      id: menuItemId,
      data: {
        choiceGroups: [choiceGroupId],
      },
    });
    changeUpdating(false);

    hideDeletePopup();
  };

  const handleSave = () => {
    choiceGroupId ? update() : create();
  };

  const handleChoiceCreate: any = (event: React.MouseEvent<unknown>) => {
    showSecondaryPopup("ChoiceModal", choiceGroupId, "");
  };

  const handleChoiceUpdate: any = (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    showSecondaryPopup("ChoiceModal", choiceGroupId, id);
  };

  const handleChoiceDelete: any = async (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    await choiceGroupsTrashChoice(choiceGroupId, id);
  };

  if (loading) return <ContentLoader />;

  const renderContent = () => {
    if (loading) return <ContentLoader />;
    if (choiceListing.length === 0)
      return (
        <Box sx={{ textAlign: "center", minWidth: "100%" }}>
          <Typography align="center" variant="h6" color="error" component="p">
            No Choices Yet...
          </Typography>
        </Box>
      );
    return choiceListing.map((choice: Choice, index: number) => (
      <Grid item xs={6} key={choice.id}>
        <Grid
          container
          sx={{
            backgroundColor: "grey.200",
            cursor: "pointer",
            padding: 1,
            alignItems: "center",
            my: "4px",
            "&:last-of-type": {
              mt: "4px",
              mb: 0,
            },
          }}
        >
          <Grid
            item
            xs={11}
            onClick={(event) => handleChoiceUpdate(event, choice.id)}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography variant="body2">
                  <strong>{choice.name}</strong>
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  <strong>{choice.price}</strong>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box sx={{ ml: 1 }}>
              <Button
                onClick={(event) => handleChoiceDelete(event, choice.id)}
                sx={{ minWidth: "100%" }}
                color="error"
              >
                <DeleteIcon sx={{ mx: 0 }} fontSize="small" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    ));
  };

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 892,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "grey.300",
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {choiceGroupId.length === 0
            ? "Add New Choice Group"
            : "Edit Choice Group"}
        </Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{ border: "1px solid", borderColor: "divider" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ p: 2, flex: 1 }}>
            <Box sx={{ mb: 0.5 }}>
              <Typography component={"span"}>Name</Typography>
            </Box>
            <TextField
              placeholder={"Extras"}
              fullWidth
              size="small"
              value={name}
              onChange={(event) => handleNameChange(event)}
            />
          </Box>
          <Box sx={{ p: 2, flex: 1 }}>
            <Box>
              <Typography component={"span"}>Required</Typography>
            </Box>
            <Box>
              <Checkbox
                checked={required}
                onChange={(event) => handleRequiredChange(event)}
                sx={{ marginLeft: "-11px" }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ px: 2, pt: 0, pb: 2, flex: 1 }}>
            <Box sx={{ mb: 0.5 }}>
              <Typography component={"span"}>Min Number Of Choices</Typography>
            </Box>
            <Box>
              <TextField
                placeholder={"00"}
                fullWidth
                type={"number"}
                size="small"
                value={minNumberOfChoices}
                onChange={(event) => handleMinChange(event)}
              />
            </Box>
          </Box>
          <Box sx={{ px: 2, pt: 0, pb: 2, flex: 1 }}>
            <Box sx={{ mb: 0.5 }}>
              <Typography component={"span"}>Max Number Of Choices</Typography>
            </Box>
            <Box>
              <TextField
                placeholder={"00"}
                fullWidth
                type={"number"}
                size="small"
                value={maxNumberOfChoices}
                onChange={(event) => handleMaxChange(event)}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ px: 2, pt: 0, pb: 2, flex: 1 }}>
            <Box sx={{ mb: 0.5 }}>
              <Typography component={"span"}>Precedence</Typography>
            </Box>
            <TextField
              placeholder={"00"}
              fullWidth
              size="small"
              value={precedence}
              onChange={(event) => handlePrecedenceChange(event)}
            />
          </Box>
        </Box>

        {choiceGroupId.length > 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  px: 2,
                  py: 0.5,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography component={"span"}>Choices</Typography>
                </Box>
                <Box>
                  <Button
                    disableElevation
                    variant="contained"
                    size="small"
                    onClick={handleChoiceCreate}
                    sx={{ ml: 2, textTransform: "none" }}
                    disabled={updating}
                    endIcon={updating && <ButtonLoader />}
                  >
                    Add New Choice
                  </Button>
                </Box>
              </Box>
            </Box>
            <Grid container columnSpacing={1} sx={{ p: 2, pt: 0 }}>
              {renderContent()}
            </Grid>
          </>
        )}

        {/* Action Bar */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="text"
            size="small"
            onClick={hideDeletePopup}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleSave}
            sx={{ ml: 2, textTransform: "none" }}
            disabled={updating}
            endIcon={updating && <ButtonLoader />}
          >
            {choiceGroupId.length === 0
              ? "Add New Choice Group"
              : "Update Choice Group"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ChoiceGroupModal;
