import { ThemeOptions } from "@mui/material/styles";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      light: "#717171",
      main: "#010101",
      dark: "#1B1B1B",
    },
    secondary: {
      light: "#33bfff",
      main: "#00b0ff",
      dark: "#007bb2",
    },
    background: {
      paper: "#fff",
      default: "#F9F9F9",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default lightThemeOptions;
