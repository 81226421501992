// actions
const SET = "groups/SET";
const SET_SELECTED = "groups/SET_SELECTED";
const SET_FILTERS = "groups/SET_FILTERS";
const SET_SELECTED_FILTERS = "groups/SET_SELECTED_FILTERS";

const DEFAULT_STATE = {
  listing: [],
  filters: [],
  selectedFilters: [],
  selected: "",
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setFilters = (filters: any[]) => ({ filters, type: SET_FILTERS });
