// actions
const SET = "timeSlots/SET";
const SET_SELECTED = "timeSlots/SET_SELECTED";
const SET_ACTIVE_INDEX = "timeSlots/SET_ACTIVE_INDEX";
const SET_ALL = "timeSlots/SET_ALL";

const DEFAULT_STATE = {
  listing: [],
  selected: "",
  activeIndex: 0,
  listingAll: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_ALL:
      return Object.assign({}, state, { listingAll: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_ACTIVE_INDEX:
      return Object.assign({}, state, { activeIndex: action.index });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingAll = (listing: any[]) => ({ listing, type: SET_ALL });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setActiveIndex = (index: number) => ({
  index,
  type: SET_ACTIVE_INDEX,
});
