import { FC, ReactElement, useEffect } from "react";

import { Pages } from "../../constants/enums";
import useApp from "../../hooks/useApp";
import useAdmin from "../../hooks/useAdmin";
import { useSelector } from "react-redux";
import UserChangePassword from "../../components/UI/UserChangePassword";

interface Props {
  children: ReactElement;
}

const PublicRoute: FC<Props> = ({ children }) => {
  const session = useSelector((state: any) => state.app.session);
  const { navigateTo , modalClose, openModal } = useApp();
  const { isAdmin } = useAdmin("admins", "admin");


  useEffect(() => {
    if (session) {
      console.log(session)
      if( !session["custom:isFirstTimeLogin"] || session["custom:isFirstTimeLogin"]===1 || session["custom:isFirstTimeLogin"]==="1")
      {
        openModal(<UserChangePassword closeModal={() => modalClose()} />);
      }
      if (isAdmin(session)) {
        navigateTo(`/dashboard/${Pages.DASHBOARD}`);
      } else if( session["cognito:groups"][0]==="staff")
      {
        navigateTo(`/dashboard/${Pages.WAITER_ORDER}`);
      }
      else {
        navigateTo(`/dashboard/${Pages.ADMINS}/${session["cognito:username"]}`);
      }
    }

    // eslint-disable-next-line
  }, [session]);

  return <>{children}</>;
};

export default PublicRoute;
