import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { closeSnackBar } from "../../../store/ducks/app";

const MainSnackbar: React.FC = () => {
  const dispatch = useDispatch();
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);
  const snackBarContent = useSelector(
    (state: any) => state.app.snackBarContent
  );
  const snackBarSeverity = useSelector(
    (state: any) => state.app.snackBarSeverity
  );
  const snackBarPersist = useSelector(
    (state: any) => state.app.snackBarPersist
  );

  return (
    <Snackbar
     anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={
        snackBarPersist ? null : snackBarSeverity === "success" ? 3000 : 6000
      }
      open={snackBarOpen}
      onClose={() => dispatch(closeSnackBar())}
    >
      <Alert
        onClose={() => dispatch(closeSnackBar())}
        elevation={6}
        variant="filled"
        severity={snackBarSeverity}
        sx={{
          position: "fixed",
          top: "16px",
          right: "16px",
        }}
      >
        {snackBarContent}
      </Alert>
    </Snackbar>
  );
};

export default MainSnackbar;
