import axios from 'axios';

async function CreateNotificationFiltersService(data:any) {
  try {
    // (Amplify Env: EtihadAirways - Dev)
    const response = await axios.post("https://2esisaud3buczgzcoh6qqh2u6m0mvbuk.lambda-url.us-east-2.on.aws/", data,
    
    );
    return response.data;
  } catch (error) {
    console.error('Error creating filters:', error);
    throw error;
  }
}

export default CreateNotificationFiltersService;
