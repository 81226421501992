import useAccount from "./useAccount";
import useArea from "./useArea";
import useComment from "./useComment";
import useConcept from "./useConcept";
import useCustomerStats from "./useCustomerStats";
import useFeature from "./useFeature";
import useFlag from "./useFlag";
import useGroup from "./useGroup";
import useGroupSetting from "./useGroupSetting";
import useInterest from "./useInterest";
import useLanguage from "./useLanguage";
import usePlanItem from "./usePlanItem";
import useSocialLink from "./useSocialLink";
import useStatus from "./useStatus";
import useTable from "./useTable";
import useTimeSlot from "./useTimeSlot";
import useUser from "./useUser";
import useFriend from "./useFriend";
import useObject from "./useObject";
import useServingArea from "./useServingArea";
import useTimeline from "./useTimeline";
import useAttachment from "./useAttachment";
import useBooking from "./useBooking";
import useNotification from "./useNotification";
import useAdminRole from "./useAdminRole";
import useAdminGroup from "./useAdminGroup";
import useUserConcepts from "./useUserConcepts";
import useTransaction from "./useTransaction";
import usePaymentStat from "./usePaymentStat";
import useBookingGuest from "./useBookingGuest";
import useMobileNotification from "./useMobileNotification";
import useParentConcepts from "./useParentConcepts";
import useMedia from "./useMedia";
import useOnlineOrder from "./useOnlineOrder";
import useMenuItem from "./useMenuItem";
import useCategory from "./useCategory";
import usePrice from "./usePrice";
import useChoice from "./useChoice";
import useChoiceGroup from "./useChoiceGroup";
import useDispatchers from "./useDispatchers";
import useMainCategory from "./useMainCategory";
import useSubCategory from "./useSubCategory";
import useShift from "./useShift";
import usePreparationAre from "./usePreparationArea";

const useResource = () => {
  const accountsApi = useAccount("accounts", "account");
  const areasApi = useArea("areas", "area");
  const conceptsApi = useConcept("concepts", "concept");
  const featuresApi = useFeature("features", "feature");
  const flagsApi = useFlag("flags", "flag");
  const groupsApi = useGroup("groups", "group");
  const groupSettingsApi = useGroupSetting("groupSettings", "groupSetting");
  const interestsApi = useInterest("interests", "interest");
  const languagesApi = useLanguage("languages", "language");
  const planItemsApi = usePlanItem("planItems", "planItem");
  const socialLinksApi = useSocialLink("socialLinks", "socialLink");
  const statusesApi = useStatus("statuses", "status");
  const tablesApi = useTable("tables", "table");
  const timeSlotsApi = useTimeSlot("timeSlots", "timeSlot");
  const usersApi = useUser("guests", "guest");
  const commentsApi = useComment("comments", "comment");
  const customerStatsApi = useCustomerStats("customerStats", "customerStats");
  const friendsApi = useFriend("friends", "friend");
  const objectsApi = useObject("objects", "object");
  const servingAreasApi = useServingArea("servingAreas", "servingArea");
  const timeLinesApi = useTimeline("timelines", "timeline");
  const attachmentsApi = useAttachment("attachments", "attachment");
  const bookingsApi = useBooking("bookings", "booking");
  const notificationsApi = useNotification("notifications", "notification");
  const adminRolesApi = useAdminRole("adminRoles", "adminRole");
  const adminGroupsApi = useAdminGroup("adminGroups", "adminGroup");
  const userConceptsApi = useUserConcepts("userConcepts", "userConcept");
  const transactionsApi = useTransaction("transactions", "transaction");
  const paymentStatsApi = usePaymentStat("paymentStats", "paymentStat");
  const bookingGuestApi = useBookingGuest("bookingGuests", "bookingGuest");
  const mobileNotifications = useMobileNotification(
    "mobileNotifications",
    "mobileNotification"
  );
  const ParentConcept = useParentConcepts("parentconcepts", "parentconcept");
  const mediaLibraryApi = useMedia("mediaLibrary", "media");
  const dispatchersApi = useDispatchers("dispatchers", "dispatcher");
  const mainCategoryApi = useMainCategory("mainCategories", "mainCategory");
  const subCategoryApi = useSubCategory("subCategories", "subCategory");
  const shiftApi = useShift("shifts", "shift");
  const preparationAreaApi = usePreparationAre(
    "preparationAreas",
    "preparationArea"
  );

  // Online Ordering / Call Center
  const onlineOrderApi = useOnlineOrder("onlineOrders", "onlineOrder");
  const menuItemApi = useMenuItem("menuItems", "menuItem");
  const categoryApi = useCategory("categories", "category");
  const priceApi = usePrice("prices", "price");
  const choiceApi = useChoice("choices", "choice");
  const choiceGroupApi = useChoiceGroup("choiceGroups", "choiceGroup");

  return {
    ...adminGroupsApi,
    ...adminRolesApi,
    ...accountsApi,
    ...areasApi,
    ...conceptsApi,
    ...featuresApi,
    ...flagsApi,
    ...groupsApi,
    ...groupSettingsApi,
    ...interestsApi,
    ...languagesApi,
    ...planItemsApi,
    ...socialLinksApi,
    ...statusesApi,
    ...tablesApi,
    ...timeSlotsApi,
    ...usersApi,
    ...commentsApi,
    ...customerStatsApi,
    ...friendsApi,
    ...objectsApi,
    ...servingAreasApi,
    ...timeLinesApi,
    ...attachmentsApi,
    ...bookingsApi,
    ...notificationsApi,
    ...userConceptsApi,
    ...transactionsApi,
    ...paymentStatsApi,
    ...bookingGuestApi,
    ...mobileNotifications,
    ...ParentConcept,
    ...mediaLibraryApi,
    ...dispatchersApi,
    ...mainCategoryApi,
    ...subCategoryApi,
    ...shiftApi,
    ...onlineOrderApi,
    ...menuItemApi,
    ...categoryApi,
    ...priceApi,
    ...choiceApi,
    ...choiceGroupApi,
    ...preparationAreaApi,
  };
};

export default useResource;
