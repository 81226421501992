import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useApp from "../../../hooks/useApp";
import useArea from "../../../hooks/useArea";

interface Props {
  day: any;
  settings: boolean;
}

const DayComponent: React.FC<Props> = ({ day, settings }) => {
  const areasDays = useSelector((state: any) => state.areas.days);
  const { showError } = useApp();
  const { areasChangeDays } = useArea("", "area");

  const handleClick = (event: any, id: string) => {
    if (settings) {
      const newSelect = new Set(areasDays);

      if (newSelect.has(id)) newSelect.delete(id);
      else newSelect.add(id);

      areasChangeDays(newSelect);
    } else {
      showError("Select Apply to selected days and timeSlots in order to edit");
    }
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        border: "1px solid",
        borderColor: "grey.200",
        backgroundColor: areasDays.has(day.name.toLowerCase())
          ? "common.black"
          : "transparent",
        color: (theme) =>
          areasDays.has(day.name.toLowerCase())
            ? "common.white"
            : theme.palette.text.primary,
        p: { xs: "4px 8px", md: "4px 8px" },
        m: "0px 4px",
        cursor: "pointer",
      }}
      onClick={(event: any) => handleClick(event, day.name.toLowerCase())}
    >
      <Typography component="span" variant="body2">
        {day.name}
      </Typography>
    </Box>
  );
};

export default DayComponent;
