import { Languages } from "../../constants/enums";

// actions
const SET = "accounts/SET";
const SET_SELECTED = "accounts/SET_SELECTED";
const SET_NEXT_TOKEN = "accounts/SET_NEXT_TOKEN";
const SET_LANGUAGE = "accounts/SET_LANGUAGE";



const DEFAULT_STATE = {
  listing: [],
  selected: null,
  language:Languages.ENGLISH,
  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.model });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_LANGUAGE:
      return Object.assign({}, state, { language: action.language });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (model: any) => ({ model, type: SET_SELECTED });
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setLanguage = (language: any) => ({ language, type: SET_LANGUAGE });

