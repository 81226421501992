import useResource from "./useResource";
import { convertToCamelCase } from "../helpers/utils";
import { Pages } from "../constants/enums";
import { Token } from "../models/pagination";
import useAuth from "./useAuth";
import useAdmin from "./useAdmin";

const useListings = (slug: string) => {
  const resource = useResource();
  const { login, register, forgetPassword, forgetPasswordSubmit } = useAuth();
  const { adminsCreate, adminsDelete } = useAdmin("admins", "admin");

  const api: any = {};
  const defaultOptions: any[] = [];
  const defaultHeadCells: any[] = [];
  const defaultDataCells: any[] = [];
  const defaultExportCells: any[] = [];
  const defaultFetchListing = (
    limit: number,
    nextToken: Token,
    setNextNextToken: any
  ) => console.log(limit, nextToken, setNextNextToken);
  const defaultChangeListing = (listing: any[]) =>
    console.log("default changeListing ", listing);
  const defaultGetResource = (id: string) =>
    console.log("default getResource ", id);
  const defaultCreateResource = (data: any, state: any) =>
    console.log(data, state);
  const defaultUpdateResource = (id: string, data: any, state: any) =>
    console.log(id, data, state);
  const defaultTrashResource = (id: string) =>
    console.log("trash default function =>", id);
  const defaultBulkTrashResource = (ids: any) => console.log(ids);
  const defaultDeleteResource = (id: string) => console.log(id);
  const defaultExportResource = () => console.log("This is working...");

  for (let page of Object.values(Pages)) {
    if (slug === page) {
      if (slug === Pages.ADMINS || slug === Pages.STAFF_MEMBERS) {
        api.createResource = adminsCreate;
        slug === Pages.ADMINS ? (api.listing = []) : (api.waiterlisting = []);
        api.deleteResource = defaultDeleteResource;
        api.trashResource = adminsDelete;
      } else if (slug === Pages.LOGIN) {
        api.createResource = login;
      } else if (slug === Pages.REGISTER) {
        api.createResource = register;
      } else if (slug === Pages.FORGOT_PASSWORD) {
        api.createResource = forgetPassword;
      } else if (slug === Pages.RESET_PASSWORD) {
        api.createResource = forgetPasswordSubmit;
      } else {
        api.options =
          resource[`${convertToCamelCase(page)}Options`] !== undefined
            ? resource[`${convertToCamelCase(page)}Options`]
            : defaultOptions;
        api.headCells =
          resource[`${convertToCamelCase(page)}HeadCells`] !== undefined
            ? resource[`${convertToCamelCase(page)}HeadCells`]
            : defaultHeadCells;
        api.dataCells =
          resource[`${convertToCamelCase(page)}DataCells`] !== undefined
            ? resource[`${convertToCamelCase(page)}DataCells`]
            : defaultDataCells;
        api.exportCells =
          resource[`${convertToCamelCase(page)}ExportCells`] !== undefined
            ? resource[`${convertToCamelCase(page)}ExportCells`]
            : defaultExportCells;

        api.changeListings =
          resource[`${convertToCamelCase(page)}ChangeListing`] !== undefined
            ? resource[`${convertToCamelCase(page)}ChangeListing`]
            : defaultChangeListing;
        api.fetchListings =
          resource[`${convertToCamelCase(page)}Fetch`] !== undefined
            ? resource[`${convertToCamelCase(page)}Fetch`]
            : defaultFetchListing;
        api.getResource =
          resource[`${convertToCamelCase(page)}Get`] !== undefined
            ? resource[`${convertToCamelCase(page)}Get`]
            : defaultGetResource;
        api.createResource =
          resource[`${convertToCamelCase(page)}Create`] !== undefined
            ? resource[`${convertToCamelCase(page)}Create`]
            : defaultCreateResource;
        api.updateResource =
          resource[`${convertToCamelCase(page)}Update`] !== undefined
            ? resource[`${convertToCamelCase(page)}Update`]
            : defaultUpdateResource;
        api.trashResource =
          resource[`${convertToCamelCase(page)}Trash`] !== undefined
            ? resource[`${convertToCamelCase(page)}Trash`]
            : defaultTrashResource;
        api.bulkTrashResource =
          resource[`${convertToCamelCase(page)}BulkTrash`] !== undefined
            ? resource[`${convertToCamelCase(page)}BulkTrash`]
            : defaultBulkTrashResource;
        api.deleteResource =
          resource[`${convertToCamelCase(page)}Delete`] !== undefined
            ? resource[`${convertToCamelCase(page)}Delete`]
            : defaultDeleteResource;
        api.exportResource =
          resource[`${convertToCamelCase(page)}Export`] !== undefined
            ? resource[`${convertToCamelCase(page)}Export`]
            : defaultExportResource;
        api.resourceModel =
          resource[`${convertToCamelCase(page)}Model`] !== undefined
            ? resource[`${convertToCamelCase(page)}Model`]
            : null;

        api.createSubscription =
          resource[`${convertToCamelCase(page)}CreateSubscription`] !==
          undefined
            ? resource[`${convertToCamelCase(page)}CreateSubscription`]
            : "";
      }
    }
  }
  return api;
};

export default useListings;
