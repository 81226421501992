import {
  CreateVariables,
  InterestBulkTrashVariables,
  InterestUpdateVariables,
  Option,
} from "./../models/app";
import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import {
  setFilters,
  setListing,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/interest";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { Interest } from "../models";
import { InterestGetVariables, InterestListingVariables } from "../models/app";
import { CreateInterestInput } from "../models/GQL_API";
import { onCreateInterest } from "../graphql/subscriptions";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: InterestListingVariables) {
    const { accountID, searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        Interest as any,
        (model: any) => {
          model.deleted("eq", "0").accountID("eq", accountID);

          if (searchText.length > 0)
            model.message("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));
      // dispatch(setFilters(listing.map((model: any) => model.name)));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: InterestGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Interest) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  async function get(params: InterestGetVariables) {
    const { id, listing } = params;

    try {
      const single: Interest | undefined =
        listing.length === 0
          ? await DataStore.query(Interest as any, id)
          : listing.find((model: Interest) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateInterestInput = {
        accountID: data.accountID,
        name: data.name.toLowerCase(),
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.icon) createInput.icon = data.icon;
      if (data.color) createInput.color = data.color;

      await DataStore.save(new Interest(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: InterestUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        Interest.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name.toLowerCase() : original!.name;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: InterestGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Interest.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: InterestBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: InterestGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Interest[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Interest as any;
  api[`${listingName}CreateSubscription`] = onCreateInterest;

  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Interest[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
