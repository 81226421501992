// actions
const SET = "areas/SET";
const SET_SELECTED = "areas/SET_SELECTED";
const SET_DAYS = "areas/SET_DAYS";
const SET_TIME_SLOTS = "areas/SET_TIME_SLOTS";
const SET_OPTIONS = "areas/SET_OPTIONS";
const SET_CONCEPT = "areas/SET_CONCEPT";
const SET_NEXT_TOKEN = "areas/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  options: [],
  concept: null,
  selected: "",
  days: new Set(),
  timeSlots: new Set(),

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_DAYS:
      return Object.assign({}, state, { days: action.days });
    case SET_TIME_SLOTS:
      return Object.assign({}, state, { timeSlots: action.timeSlots });
    case SET_OPTIONS:
      return Object.assign({}, state, { options: action.options });
    case SET_CONCEPT:
      return Object.assign({}, state, { concept: action.concept });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setOptions = (options: any[]) => ({ options, type: SET_OPTIONS });
export const setSelected = (id: any) => ({ id, type: SET_SELECTED });
export const setDays = (days: any) => ({ days, type: SET_DAYS });
export const setAreaConcept = (concept: any) => ({
  concept,
  type: SET_CONCEPT,
});
export const setTimeSlots = (timeSlots: any) => ({
  timeSlots,
  type: SET_TIME_SLOTS,
});
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});
