import { DataStore, SortDirection } from "aws-amplify";
import { PaymentStat } from "../models";
import { CreatePaymentStatInput } from "../models/GQL_API";
import { HeadCell } from "../models/dataTable";
import useApp from "../hooks/useApp";
import {
  CreateVariables,
  GetVariables,
  PaymentStatListingVariables,
  PaymentStatUpdateVariables,
} from "../models/app";
import { getMonthNameFromNum } from "../helpers/utils";
import { useDispatch } from "react-redux";

import { setListing, setSelected } from "../store/ducks/paymentStat";
import { onCreatePaymentStat } from "../graphql/subscriptions";

const useResource = (listingName: string, singleName: string) => {
  const { showError } = useApp();
  const dispatch = useDispatch();

  async function fetch(props: PaymentStatListingVariables) {
    const {
      accountId,
      year,
      fromMonth,
      toMonth,
      searchText,
      startIndex,
      limit,
    } = props;

    try {
      const listing = await DataStore.query(
        PaymentStat as any,
        (PaymentStat: any) => {
          return PaymentStat;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );
      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: PaymentStat | undefined = await DataStore.query(
        PaymentStat as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: PaymentStatUpdateVariables) {
    try {
      const { id, listing, data } = params;

      // Online
      // Get month name
      // let monthName = getMonthNameFromNum(data.month);

      // const updateInput: UpdatePaymentStatInput = {
      //   id: data.id,
      //   year: data.year,
      //   month: data.month,
      //   monthName: monthName,
      //   earning: data.earning,
      //   refund: data.refund,
      //   earningTrxCount: data.earningTrxCount,
      //   refundTrxCount: data.refundTrxCount,
      // };

      // const updated = await API.graphql<GraphQLQuery<PaymentStat>>({
      //   query: updatePaymentStat,
      //   variables: { input: updateInput },
      //   authMode: isAuth
      //     ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //     : GRAPHQL_AUTH_MODE.AWS_IAM,
      // });
      // return updated;

      // Offline
      const original = await get({ id });
      const updated = await DataStore.save(
        PaymentStat.copyOf(original!, (updated) => {
          updated.earning = data.earning
            ? parseFloat(data.earning)
            : original!.earning;
          updated.refund = data.refund
            ? parseFloat(data.refund)
            : original!.refund;

          updated.earningTrxCount = data.earningTrxCount
            ? parseInt(data.earningTrxCount)
            : original!.earningTrxCount;
          updated.refundTrxCount = data.refundTrxCount
            ? parseInt(data.refundTrxCount)
            : original!.refundTrxCount;
        })
      );

      return updated;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    try {
      const { userID, userName, data } = params;

      // Get month name
      let monthName = getMonthNameFromNum(data.month);

      const createInput: CreatePaymentStatInput = {
        accountID: data.accountID,
        conceptID: "",
        year: parseInt(data.year),
        month: parseInt(data.month),
        monthName: monthName,
        earning: parseFloat(data.earning),
        refund: parseFloat(data.refund),
        earningTrxCount: parseInt(data.earningTrxCount),
        refundTrxCount: parseInt(data.refundTrxCount),
        deleted: "0",
        createdByID: userID,
        createdByName: userName,
        createdAt: new Date().toLocaleString(),
      };

      // Online
      // const createdPaymentStat = await API.graphql<GraphQLQuery<PaymentStat>>({
      //   query: createPaymentStat,
      //   variables: { input: createInput },
      //   authMode: isAuth
      //     ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //     : GRAPHQL_AUTH_MODE.AWS_IAM,
      // });

      // Offline
      const createdPaymentStat: PaymentStat = await DataStore.save(
        new PaymentStat(createInput as any)
      );

      return createdPaymentStat;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "accountID",
      numeric: false,
      disablePadding: false,
      label: "Account ID",
    },
    {
      id: "year",
      numeric: false,
      disablePadding: false,
      label: "Year",
    },
    {
      id: "month",
      numeric: false,
      disablePadding: false,
      label: "Month",
    },
    {
      id: "monthName",
      numeric: false,
      disablePadding: false,
      label: "Month Name",
    },
    {
      id: "earning",
      numeric: false,
      disablePadding: false,
      label: "Earning",
    },
    {
      id: "refund",
      numeric: false,
      disablePadding: false,
      label: "Refund",
    },
    {
      id: "earningTrxCount",
      numeric: false,
      disablePadding: false,
      label: "Earning Trx Count",
    },
    {
      id: "refundTrxCount",
      numeric: false,
      disablePadding: false,
      label: "Refund Trx Count",
    },
  ];

  const dataCells: readonly string[] = [
    "accountID",
    "year",
    "month",
    "monthName",
    "earning",
    "refund",
    "earningTrxCount",
    "refundTrxCount",
  ];

  const api: any = {};

  api[`${listingName}Model`] = PaymentStat as any;
  api[`${listingName}CreateSubscription`] = onCreatePaymentStat;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Update`] = update;
  api[`${listingName}Create`] = create;

  api[`${listingName}ChangeListing`] = (listing: PaymentStat[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (model: any) =>
    dispatch(setSelected(model));

  return api;
};

export default useResource;
