import { CreateCategoryInput, UpdateCategoryInput } from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { Category } from "../models";
import { createCategory, updateCategory } from "../graphql/mutations";
import { getCategory } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setListing,
  setNextToken,
  setLastIndex,
  setPagination,
  setAllListing,
  setFilters,
  setSelectedFilters,
  setFilter,
} from "../store/ducks/category";
import { getDateFormatted } from "../helpers/utils";
import { onCreateCategory } from "../graphql/subscriptions";
import { Languages } from "../constants/enums";
import { getTimeInUAE } from "../helpers/utils";
import useGeneralPagination from "./useGeneralPagination";

const useCategory = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const language = useSelector((state: any) => state.accounts.language);
  const session = useSelector((state: any) => state.app.session);

  const categoriesListing = useSelector(
    (state: any) => state.categories.allListing
  );

  let paginationListing = useSelector(
    (state: any) => state.categories.pagination
  );

  const { generalFetch, generalFetchAllByConceptID } = useGeneralPagination(
    listingName,
    singleName
  );

  // Fetch with pagination, using concept index
  async function fetch(params: ListingByConceptVariables) {
    try {
      // const { conceptID, searchText } = params;
      const listing = await generalFetch(params, "categoryByConceptID");
      return listing;

      // Sort result in ascending order
      // const listSorted = listing.sort((a: any, b: any) => {
      //   return a.precedence - b.precedence;
      // });

      // dispatch(setFilter([searchText, conceptID]));
      // dispatch(setListing(listing));
      // return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  // Fetch without pagination using concept index
  async function fetchCategoryByConceptID(params: ListingByConceptVariables) {
    try {
      // const { conceptID, searchText, limit } = params;

      const categories = await generalFetchAllByConceptID(
        params,
        "categoryByConceptID"
      );
      dispatch(setAllListing(categories));
      return categories;

      // Sort result in ascending order
      // return allItems.sort((a: any, b: any) => {
      //   return a.precedence - b.precedence;
      // });

      // Return cached list
      // if (
      //   categoriesListing.length === 0 ||
      //   conceptID !== categoriesListing[0].conceptID
      // ) {
      // } else {
      //   return categoriesListing;
      // }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const category: any = await API.graphql({
        query: getCategory,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return category.data.getCategory;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: any) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Category) => model.id === id);
      return model ? model.name : "";
    }

    return "";
  };

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
    multiLanguages = JSON.stringify(multiLanguages);

    try {
      const createInput: CreateCategoryInput = {
        name: data.name.toLowerCase().trim(),
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : "",
        preparationAreaID: data.preparationAreaID ? data.preparationAreaID : "",
        subCategory: data.subCategory ? data.subCategory : null,
        guestView: data.guestView ? data.guestView : false,
        guestOrder: data.guestOrder ? data.guestOrder : false,
        staffOrder: data.staffOrder ? data.staffOrder : false,
        precedence: data.precedence ? data.precedence : "0",
        conceptID: data.conceptID,
        multiLanguages: multiLanguages,
        createdAt: getDateFormatted(getTimeInUAE()),
        createdByID: userID,
        createdByName: userName,
      };

      const Category = await API.graphql<GraphQLQuery<Category>>({
        query: createCategory,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      return Category;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      let multiLanguages: any = [];
      if (original.multiLanguages) {
        multiLanguages = new Map(JSON.parse(original.multiLanguages));
        if (data.name) {
          multiLanguages.set(`${language}-name`, data.name);
        }
        multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
      } else {
        if (data.name) {
          multiLanguages.push([`${language}-name`, data.name]);
        }
        multiLanguages = JSON.stringify(multiLanguages);
      }

      const updateInput: UpdateCategoryInput = {
        id: original.id,
        name:
          data.name && language === Languages.ENGLISH
            ? data.name.toLowerCase()
            : original!.name,
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : original!.image,
        precedence: data.precedence ? data.precedence : original.precedence,
        subCategory: data.subCategory ? data.subCategory : original.subCategory,
        guestView: data.guestView,
        guestOrder: data.guestOrder,
        staffOrder: data.staffOrder,
        multiLanguages: multiLanguages,
        preparationAreaID: data.preparationAreaID
          ? data.preparationAreaID
          : original.preparationAreaID,
        _version: original._version,
      };

      const Category: any = await API.graphql<GraphQLQuery<Category>>({
        query: updateCategory,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      return Category.data.updateCategory;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: any) {
    let original = await get(params);
    const updateInput: UpdateCategoryInput = {
      id: original.id,
      deleted: "1",
      _version: original._version,
    };

    await API.graphql<GraphQLQuery<Category>>({
      query: updateCategory,
      variables: { input: updateInput },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    for (let i = 0; i < categoriesListing.length; i++) {
      if (categoriesListing[i].id === original.id) {
        categoriesListing.splice(i, 1);
        dispatch(setListing(categoriesListing));
        break;
      }
    }
    for (let i = 0; i < paginationListing.length; i++) {
      if (paginationListing[i].id === original.id) {
        paginationListing.splice(i, 1);
        dispatch(setPagination(paginationListing));
        break;
      }
    }

    showConfirm(`${singleName} has been moved to trash successfully`);
  }

  async function bulkTrash(params: any) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });
    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: Category[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = Category as any;
  api[`${listingName}CreateSubscription`] = onCreateCategory;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchCategoryByConceptID;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: Category[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}ChangeAllListing`] = (listing: Category[]) => {
    dispatch(setAllListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };

  return api;
};

export default useCategory;
