import { useState } from "react";

const useLoading = () => {
  const [loading, setLoading] = useState<boolean>(true);

  return {
    loading,
    changeLoading: (loadingState: boolean) => setLoading(loadingState),
  };
};

export default useLoading;
