// actions
const SET = "transactions/SET";
const SET_SELECTED = "transactions/SET_SELECTED";
const SET_EARNING_AMOUNT = "transactions/SET_EARNING_AMOUNT";
const SET_REFUND_AMOUNT = "transactions/SET_REFUND_AMOUNT";
const SET_LINKS_SENT = "transactions/SET_LINKS_SENT";

const DEFAULT_STATE = {
  listing: [],
  selected: "",
  earningAmount: 0,
  refundAmount: 0,
  linksSent: 0,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_EARNING_AMOUNT:
      return Object.assign({}, state, { earningAmount: action.earningAmount });
    case SET_REFUND_AMOUNT:
      return Object.assign({}, state, { refundAmount: action.refundAmount });
    case SET_LINKS_SENT:
      return Object.assign({}, state, { linksSent: action.linksSent });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setEarningAmount = (earningAmount: number) => ({
  earningAmount,
  type: SET_EARNING_AMOUNT,
});
export const setRefundAmount = (refundAmount: number) => ({
  refundAmount,
  type: SET_REFUND_AMOUNT,
});
export const setLinksSent = (linksSent: number) => ({
  linksSent,
  type: SET_LINKS_SENT,
});
