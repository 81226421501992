// actions
const SET = "statuses/SET";
const SET_SEARCH_TEXT = "statuses/SET_SEARCH_TEXT";
const SET_SELECTED = "statuses/SET_SELECTED";
const SET_FILTERS = "statuses/SET_FILTERS";
const SET_SELECTED_FILTERS = "statuses/SET_SELECTED_FILTERS";

const DEFAULT_STATE = {
  listing: [],
  filters: [],
  selectedFilters: [],
  searchText: "",
  selected: new Set<string>(),
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.selected });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setFilters = (filters: string[]) => ({
  filters,
  type: SET_FILTERS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setSelected = (selected: Set<string>) => ({
  selected,
  type: SET_SELECTED,
});
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
