import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { primaryLight } from "../../../constants/colors";
import ImageContainer from "../ImageContainer";

interface Props {
  isSelected: boolean;
  file: any;
  onClick: any;
}

const MediaItem: React.FC<Props> = ({ isSelected, file, onClick }) => {
  const itemWidth: number = 100 / 8;

  return (
    <Box
      sx={{
        border: 4,
        borderColor: (theme) =>
          isSelected ? primaryLight(theme) : "transparent",
        width: `${itemWidth}%`,
        float: "left",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box sx={{ maxWidth: "100%" }}>
        <ImageContainer
          src={file.fileUrl}
          thumb={file.fileUrl}
          alt={file.filename}
        />
        <Typography
          color="text.secondary"
          variant="body2"
          paragraph
          sx={{ fontWeight: 700 }}
        >
          {file.filename
            ? file.filename.length > 18
              ? `${file.filename.slice(0, 18)}...`
              : file.filename
            : "Filename not available"}
        </Typography>
      </Box>
    </Box>
  );
};

export default MediaItem;
