import ReactDOM from "react-dom/client";
import Amplify, {
  AuthModeStrategyType,
  DataStore,
  syncExpression,
} from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { CacheProvider, EmotionCache } from "@emotion/react";
import {
  ThemeProvider,
  CssBaseline,
  GlobalStyles,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import App from "./App";
import store from "./store";
import ScrollToTop from "./ScrollToTop";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import config from "./aws-exports";

import createEmotionCache from "./helpers/createEmotionCache";
import lightThemeOptions from "./theme/lightThemeOptions";
import {
  Account,
  Language,
  Booking,
  Comment,
  User,
  MainCategory,
  SubCategory,
  Category,
  MenuItem,
  ChoiceGroups,
  Choice,
  ServingArea,
  PreparationArea,
  QRcode,
  UserPushToken,
  Price,
  Cart,
  AdminStatus,
  OnlineOrder,
  OrderStatus,
  Zone,
  Shift,
  CallWaiter,
  MobileNotification,
  UserIP,
  EmailVerification,
  Review,
  SocialLink,
  Flag,
  Interest,
  Object,
  Friend,
  Call,
  Notification,
  DiscountCoupon,
  NotificationReceiver,
  ReservationStatus,
  Transaction,
  PaymentStat,
  
 } from "./models";
import React from "react";
import { disableSync } from "./helpers/dbSync";

// Amplify.Logger.LOG_LEVEL = "DEBUG";
Amplify.configure(config);

const currentUrl = window.location.href;

if (currentUrl.includes("payment") || currentUrl.includes("reservations")) {
  let bookingHash = currentUrl.split("/")[4];
  DataStore.configure(disableSync(bookingHash));
} else {

  //get current url
  let url = window.location.hostname;

  DataStore.configure({
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    maxRecordsToSync: 2000,
    syncExpressions: [
      // Never sync User data
      syncExpression(User, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      // Never sync Booking data
      syncExpression(Booking, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      // Never sync Comment data
      syncExpression(Comment, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(MainCategory, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(SubCategory, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Category, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(MenuItem, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(ChoiceGroups, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Choice, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(ServingArea, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(PreparationArea, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(QRcode, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(UserPushToken, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Price, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Cart, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(AdminStatus, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(OnlineOrder, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(OrderStatus, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Zone, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Shift, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(CallWaiter, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Language, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(MobileNotification, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(UserIP, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(EmailVerification, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Review, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(SocialLink, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Flag, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Interest, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Object, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Friend, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Call, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Notification, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(DiscountCoupon, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(NotificationReceiver, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(ReservationStatus, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Transaction, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(PaymentStat, () => {
        return (model) => model.id("eq", "NEVER_MATCH_ME");
      }),
      syncExpression(Account, () => {
        return (model) => model.siteAddress("contains", url);
      }),
    ],
  });
}

let lightTheme = createTheme(lightThemeOptions);

lightTheme = responsiveFontSizes(lightTheme);
const emotionCache: EmotionCache = createEmotionCache();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              ".html-content": {
                paddingLeft: 8,
                paddingRight: 8,
              },
              ".html-content p": {
                backgroundColor: "#FFF9C4",
                padding: 8,
                margin: 0,
                marginBottom: 8,
              },
              ".reservation-comment p": {
                margin: 0,
              },
              ".MuiSnackbar-root": {
                right: "24px !important",
                left: "24px !important",
              },
              ".MuiButton-contained": {
                color: "white",
              },
              ".MuiPaper-root-MuiSnackbarContent-root": {
                width: "100%",
              },
              ".MuiDrawer-paper": {
                borderRight: "none !important",
              },
              ".MuiAccordionSummary-root .MuiAccordionSummary-content": {
                margin: "12px 8px !important",
              },
              ".MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded":
                {
                  margin: "12px 8px !important",
                },
              ".MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "0 !important",
              },
              ".MuiAccordionSummary-root.Mui-expanded .MuiTypography-root": {
                marginLeft: "0 !important",
              },
              ".tox-tinymce": {
                borderWidth: "1px !important",
                borderRadius: "0px !important",
              },
              "#editor textarea": {
                display: "none",
              },
              ".sketch-picker ": {
                borderRadius: "0px !important",
                width: "calc(100% - 20px) !important",
                boxShadow: "none !important",
              },
              "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                backgroundColor: "#eee",
                maxWidth: 10,
                maxHeight: 10,
              },
              "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                minHeight: 10,
              },
              "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                {
                  backgroundColor: "#717171",
                },
              "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                {
                  backgroundColor: "#717171",
                },
              "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                {
                  backgroundColor: "#717171",
                },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              ".recharts-pie-labels": {
                display: "none",
              },
            }}
          />
          <Router>
            <ScrollToTop />
            <App />
          </Router>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
