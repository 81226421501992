import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import {
  commonWhite,
  primaryMain,
  greyVariations,
} from "../../../constants/colors";
import { openSnackBar } from "../../../store/ducks/app";
import ImageContainer from "../ImageContainer";
import { cloudinaryURL } from "../../../constants/enums";

const AttachmentDetails: React.FC = () => {
  const dispatch = useDispatch();
  const mediaLibraryListing = useSelector(
    (state: any) => state.mediaLibrary.listing
  );
  const mediaLibrarySelected = useSelector(
    (state: any) => state.mediaLibrary.selected
  );

  let attachment: any = null;

  if (mediaLibrarySelected.size > 0) {
    attachment = mediaLibraryListing.find(
      (file: any) => file.id === mediaLibrarySelected.values().next().value
    );
  }

  const handleCopyUrl = () => {
    window.navigator.clipboard.writeText(
      `${cloudinaryURL}${attachment.fileUrl}`
    );

    dispatch(openSnackBar("File url copied to clipboard", "success"));
  };

  if (!attachment) return null;

  return (
    <>
      <Typography
        color="text.secondary"
        variant="body2"
        paragraph
        sx={{ textTransform: "uppercase", fontWeight: 700 }}
      >
        attachment details
      </Typography>
      <Box
        sx={{ maxWidth: 160, background: (theme) => commonWhite(theme), mb: 1 }}
      >
        <ImageContainer
          src={attachment.fileUrl}
          thumb={attachment.fileUrl}
          alt={attachment.filename}
        />
      </Box>
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        {attachment.filename}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {attachment.createdAt.split("T")[0]}
      </Typography>
      <Button
        size="small"
        disableRipple
        disableFocusRipple
        disableTouchRipple
        color="error"
        sx={{
          textDecoration: "underline",
          textTransform: "capitalize",
          p: 0,
          mb: 1,
          "&:hover": {
            background: "transparent",
            textDecoration: "underline",
          },
        }}
      >
        Delete permanently
      </Button>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="body2">File URL:</Typography>
      <Box
        sx={{
          border: (theme) => `2px solid ${primaryMain(theme)}`,
          background: (theme) => greyVariations(theme, 100),
          p: "4px 8px",
          mb: 1,
        }}
      >
        <Typography
          noWrap
          sx={{
            textOverflow: "unset",
          }}
        >
          {`${cloudinaryURL}${attachment.fileUrl}`}
        </Typography>
      </Box>
      <Button
        size="small"
        variant="outlined"
        sx={{ mb: 2, textTransform: "capitalize" }}
        onClick={handleCopyUrl}
      >
        Copy URL to clipboard
      </Button>
    </>
  );
};

export default AttachmentDetails;
