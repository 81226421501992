import {
  CreateVariables,
  ServingAreaBulkTrashVariables,
  ServingAreaUpdateVariables,
  Option,
} from "./../models/app";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSelected,
  setNextToken,
} from "../store/ducks/servingArea";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { ServingArea } from "../models";
import {
  ServingAreaGetVariables,
  ServingAreaListingVariables,
} from "../models/app";
import {
  CreateServingAreaInput,
  UpdateServingAreaInput,
} from "../models/GQL_API";
import {
  createServingArea,
  deleteServingArea,
  updateServingArea,
} from "../graphql/mutations";
import { getServingArea, listServingAreas } from "../graphql/queries";
import { onCreateServingArea } from "../graphql/subscriptions";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  async function fetch(params: ServingAreaListingVariables) {
    const { limit, searchText } = params;
    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      const ListData: any = await API.graphql<ServingArea>({
        query: listServingAreas,
        variables: { filter, limit, nextToken: nextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = ListData.data.listServingAreas.nextToken;
      const listing = ListData.data.listServingAreas.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: ServingAreaGetVariables) {
    const { id, listing } = params;

    try {
      let single: ServingArea | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<ServingArea>({
          query: getServingArea,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getServingArea;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateServingAreaInput = {
        name: data.name.toLowerCase().trim(),
        fees: data.fees,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await API.graphql<ServingArea>({
        query: createServingArea,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      if (data.listing) {
        dispatch(setListing([...data.listing, model]));
      }
      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ServingAreaUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateServingAreaInput = {
        id: original.id,

        name: data.name ? data.name.toLowerCase().trim() : original!.name,
        fees: data.fees ? data.fees : original!.fees,

        _version: original._version,
      };

      await API.graphql<ServingArea>({
        query: updateServingArea,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ServingAreaGetVariables) {
    try {
      const original: any = await get(params);

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateServingAreaInput = {
        id: original.id,
        deleted: "1",
        _version: original._version,
      };

      await API.graphql<ServingArea>({
        query: updateServingArea,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ServingAreaBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ServingAreaGetVariables) {
    const { id, listing } = params;

    try {
      await API.graphql<ServingArea>({
        query: deleteServingArea,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: ServingArea[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "category"];

  const api: any = {};

  api[`${listingName}Model`] = ServingArea as any;
  api[`${listingName}CreateSubscription`] = onCreateServingArea;

  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: ServingArea[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
