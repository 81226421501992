import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ErrorBoundary from "./components/UI/ErrorBoundary";
import MainLoader from "./components/UI/MainLoader";
import MainModal from "./components/UI/MainModal";
import MainSnackbar from "./components/UI/MainSnackbar";
import useApp from "./hooks/useApp";
import useLoading from "./hooks/useLoading";
import useAccount from "./hooks/useAccount";
import useFeature from "./hooks/useFeature";
import useConcept from "./hooks/useConcept";
import useAdminGroup from "./hooks/useAdminGroup";
import { useSelector } from "react-redux";
import {
  AdminPermissions,
  ConceptListingVariables,
  IBookingsSyncDuration,
  ListingVariables,
  UserConceptsListingVariables,
} from "./models/app";
import { Account, Concept, Feature, UserConcepts } from "./models";
import { DataStore } from "aws-amplify";
import PublicRoute from "./containers/PublicRoute";
import usePermissions from "./hooks/usePermissions";
import useUserConcepts from "./hooks/useUserConcepts";
import { LOCAL_STORAGE } from "./constants/enums";
import {
  getBookingDateRange,
  setBookingDateRange,
} from "./helpers/localStorage";
import { getDateFormatted } from "./helpers/utils";

const Admin = lazy(() => import("./containers/Dashboard"));
const AuthComponent = lazy(() => import("./containers/Auth"));
const EmailTemplates = lazy(() => import("./containers/EmailTemplates"));

const App: React.FC = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);
  const { loading, changeLoading } = useLoading();
  const {
    fetchSession,
    setSession,
    clearSession,
    setPermissions,
    setConcepts,
  } = useApp();
  const { getAdminPermissions } = usePermissions();
  const { userConceptsFetch } = useUserConcepts("userConcepts", "userConcept");
  const { accountsFetchByDomainOnline } = useAccount("accounts", "account");
  const { featuresFetchAll, featuresChangeListingAll } = useFeature(
    "features",
    "feature"
  );
  const { adminGroupsFetchCurrentUser } = useAdminGroup(
    "adminGroups",
    "adminGroup"
  );
  const { conceptsChangeSelected, conceptsChangeListing, conceptsFetch } =
    useConcept("concepts", "concepts");

  const initApp = useCallback(async (session: any) => {
    const filteredConcepts: UserConcepts[] = [];

    const params: ListingVariables = {
      searchText: "",
      startIndex: 0,
      limit: 1000,
    };

    const userConceptParams: UserConceptsListingVariables = {
      ...params,
      userID: session?.sub,
    };

    try {
      const data = await Promise.all([
        accountsFetchByDomainOnline(true),
        featuresFetchAll(params),
        userConceptsFetch(userConceptParams),
      ]);

      const accountsSelected: Account = data[0];
      const featuresListingAll: Feature[] = data[1];
      const userConcepts: UserConcepts = data[2][0];

      if (featuresListingAll.length > 0)
        featuresChangeListingAll(featuresListingAll);

      const conceptsParams: ConceptListingVariables = {
        ...params,
        accountID: accountsSelected.id,
      };

      const resultData: any[] = await Promise.all([
        conceptsFetch(conceptsParams),
        adminGroupsFetchCurrentUser(session.sub),
      ]);


      const allConcepts: Concept[] = resultData[0];
      const userGroup = resultData[1];

      const permissions: AdminPermissions = await getAdminPermissions({
        userGroup,
      });

      setPermissions(permissions);

      if (userConcepts) {
        for (let concept of allConcepts) {
          if (userConcepts.concepts!.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }

        setConcepts(userConcepts);
      }

      conceptsChangeListing(allConcepts);

      const storageConcept: string | null = localStorage.getItem(
        LOCAL_STORAGE.SELECTED_CONCEPT
      );

      if (storageConcept) {
        conceptsChangeSelected(storageConcept);
      } else {
        if (filteredConcepts.length > 0) {
          for (var j = 0; j < filteredConcepts.length; j++) {
            if (
              userConcepts &&
              userConcepts.concepts &&
              userConcepts.concepts.includes(filteredConcepts[j].id)
            ) {
              conceptsChangeSelected(filteredConcepts[j].id);
              break;
            }
          }
        }
      }

      const bookingsSyncDuration: IBookingsSyncDuration =
        getBookingDateRange(userConcepts);
      const { fromDate, toDate } = bookingsSyncDuration;
      setBookingDateRange(fromDate, toDate);

      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, "false");
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, "false");
      localStorage.setItem(
        LOCAL_STORAGE.BOOKING_DATE,
        getDateFormatted(new Date())
      );

      setSession(session);
      changeLoading(false);
    } catch (err: Error | any) {
      clearSession();
      changeLoading(false);
    }

    // eslint-disable-next-line
  }, []);

  const getSession = async () => {
    try {
      const session = await fetchSession();

      initApp(session);
    } catch (err: Error | any) {
      sync();
    }
  };

  const sync = async () => {
    await DataStore.start();

    initApp(null);
  };

  useEffect(() => {
    getSession();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }

    function handleOffline() {
      setIsOnline(false);
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (loading) return <MainLoader />;
  if (!isOnline)
    return (
      <>
        <Snackbar
          open={!isOnline}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="error">You are currently offline!</Alert>
        </Snackbar>
        <MainLoader />;
      </>
    );

  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<MainLoader />}>
          <Routes>
            <Route
              path="/*"
              element={
                <PublicRoute>
                  <AuthComponent />
                </PublicRoute>
              }
            />
            <Route path="dashboard/*" element={<Admin />} />
            <Route path="templates/*" element={<EmailTemplates />} />
          </Routes>
        </Suspense>
      </DndProvider>
      {modalOpen && <MainModal />}
      {snackBarOpen && <MainSnackbar />}
    </ErrorBoundary>
  );
};

export default App;
