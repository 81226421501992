import { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useApp from "../../../hooks/useApp";
import DayComponent from "../../Dashboard/FloorEditor/DayComponent";
import useArea from "../../../hooks/useArea";
import TimeSlotComponent from "../../Dashboard/FloorEditor/TimeSlotComponent";
import TrueFalse from "../TrueFalse";
import useGroupSetting from "../../../hooks/useGroupSetting";
import ButtonLoader from "../ButtonLoader";
import useUpdating from "../../../hooks/useUpdating";
import useLoading from "../../../hooks/useLoading";
import ContentLoader from "../ContentLoader";
import { useSelector } from "react-redux";
import {
  CreateVariables,
  GroupSettingGetVariables,
  GroupSettingUpdateVariables,
} from "../../../models/app";

interface Props {
  groupID: string;
  settingID: string;
}

const days: any[] = [
  { name: "Monday", id: 1 },
  { name: "Tuesday", id: 2 },
  { name: "Wednesday", id: 3 },
  { name: "Thursday", id: 4 },
  { name: "Friday", id: 5 },
  { name: "Saturday", id: 6 },
  { name: "Sunday", id: 7 },
];

interface StateProps {
  amount: number;
  needsDeposit: boolean;
  needsVerification: boolean;
  statusID: string;
}

const GroupSettingModal: React.FC<Props> = ({ groupID, settingID }) => {
  const initialState: StateProps = {
    needsDeposit: true,
    needsVerification: true,
    amount: 0,
    statusID: "aa42f7e6-1f36-4aa9-bbea-b1d4bcd45966",
  };
  const [state, setState] = useState(initialState);
  const session = useSelector((state: any) => state.app.session);
  const statusesListing = useSelector((state: any) => state.statuses.listing);
  const timeSlotsListing = useSelector((state: any) => state.timeSlots.listing);
  const areasDays = useSelector((state: any) => state.areas.days);
  const areasTimeSlots = useSelector((state: any) => state.areas.timeSlots);
  const groupSettingsListing = useSelector(
    (state: any) => state.groupSettings.listing
  );
  const { hideDeletePopup } = useApp();
  const { loading, changeLoading } = useLoading();
  const { updating, changeUpdating } = useUpdating();
  const { areasChangeDays, areasChangeTimeSlots } = useArea("areas", "area");
  const { groupSettingsGet, groupSettingsCreate, groupSettingsUpdate } =
    useGroupSetting("groupSettings", "groupSetting");

  useEffect(() => {
    areasChangeDays(new Set());
    areasChangeTimeSlots(new Set());

    const fetch = async () => {
      const params: GroupSettingGetVariables = {
        id: settingID,
        listing: groupSettingsListing,
      };
      const settingData = await groupSettingsGet(params);

      setState({
        amount: settingData.amount,
        needsDeposit: settingData.needsDeposit,
        needsVerification: settingData.needsVerification,
        statusID: settingData.statusID,
      });

      areasChangeDays(new Set(settingData.days));
      areasChangeTimeSlots(new Set(settingData.timeSlots));

      changeLoading(false);
    };

    settingID.length > 0 ? fetch() : changeLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { needsDeposit, needsVerification, amount, statusID } = state;

  const create = async () => {
    changeUpdating(true);

    const createInput: any = {
      groupID: groupID,
      isDefault: false,
      statusID,
      needsVerification,
      needsDeposit,
      amount,
      days: Array.from(areasDays),
      timeSlots: Array.from(areasTimeSlots),
    };

    const groupSettingParams: CreateVariables = {
      userID: session.sub,
      userName: session.name,
      data: createInput,
    };

    await groupSettingsCreate(groupSettingParams);

    changeUpdating(false);

    hideDeletePopup();
  };

  const update = async () => {
    changeUpdating(true);

    const updateInput: any = {
      groupID: groupID,
      isDefault: false,
      statusID,
      needsVerification,
      needsDeposit,
      amount,
      days: Array.from(areasDays),
      timeSlots: Array.from(areasTimeSlots),
    };

    const groupSettingParams: GroupSettingUpdateVariables = {
      id: settingID,
      listing: groupSettingsListing,
      data: updateInput,
    };

    await groupSettingsUpdate(groupSettingParams);

    changeUpdating(false);

    hideDeletePopup();
  };

  const handleSave = () => (settingID.length > 0 ? update() : create());

  if (loading) return <ContentLoader />;

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 892,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "grey.300",
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {settingID.length === 0
            ? "New Exception Settings"
            : "Edit Exception Settings"}
        </Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{ border: "1px solid", borderColor: "divider" }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Select Days
          </Typography>
          <Box sx={{ ml: "-4px" }}>
            {days.map((day: any) => (
              <DayComponent key={day.id} day={day} settings={true} />
            ))}
          </Box>
          {areasDays.size > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Select TimeSlots
              </Typography>
              <Box sx={{ ml: "-4px" }}>
                {timeSlotsListing.map((timeSlot: any) => (
                  <TimeSlotComponent
                    key={timeSlot.id}
                    timeSlot={timeSlot}
                    settings={true}
                  />
                ))}
              </Box>
            </Box>
          )}
          {areasTimeSlots.size > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Choose Settings
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography gutterBottom>Needs Deposit ?</Typography>
                <TrueFalse
                  value={needsDeposit}
                  setValue={(event: any) => {
                    setState({
                      ...state,
                      needsDeposit:
                        event.target.value === "true" ? true : false,
                    });
                  }}
                  trueLabel="Yes"
                  falseLabel="No"
                  direction="row"
                />
                <Box sx={{ position: "relative" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: 14,
                      transform: "translateY(-50%)",
                      color: "grey.600",
                    }}
                  >
                    EGP
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={needsDeposit === false}
                    value={amount.toString()}
                    type="number"
                    sx={{ "& .MuiOutlinedInput-input": { pl: 7 } }}
                    onChange={(event: any) =>
                      setState({
                        ...state,
                        amount: parseInt(event.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography gutterBottom>Needs Verification ?</Typography>
                <TrueFalse
                  value={needsVerification}
                  setValue={(event: any) => {
                    setState({
                      ...state,
                      needsVerification:
                        event.target.value === "true" ? true : false,
                    });
                  }}
                  trueLabel="Yes"
                  falseLabel="No"
                  direction="row"
                />
              </Box>
              <Typography gutterBottom>Default Status</Typography>
              <TextField
                fullWidth
                select
                id="bulk-actions-select"
                size="small"
                value={statusID}
                onChange={(event: any) =>
                  setState({ ...state, statusID: event.target.value })
                }
              >
                {statusesListing.map((status: any) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="text"
            size="small"
            onClick={hideDeletePopup}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleSave}
            sx={{ ml: 2, textTransform: "none" }}
            disabled={updating}
            endIcon={updating && <ButtonLoader />}
          >
            {`${
              settingID.length === 0 ? "Create New" : "Update"
            } Exception Setting`}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default GroupSettingModal;
