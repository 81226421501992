// actions
const SET = "mediaLibrary/SET";
const SET_SEARCH_TEXT = "mediaLibrary/SET_SEARCH_TEXT";
const SET_SELECTED = "mediaLibrary/SET_SELECTED";

const SET_NEXT_TOKEN = "mediaLibrary/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: new Set(),

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.selected });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (selected: any) => ({
  selected,
  type: SET_SELECTED,
});
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});
