import {
  getPlanItem,
  listPlanItems,
  planItemByConceptID,
} from "./../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  CreateVariables,
  PlanItemBulkTrashVariables,
  PlanItemUpdateVariables,
  Option,
  ListingVariables,
} from "./../models/app";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSaveChanges,
  setSelected,
  setNextToken,
  changeLimit,
  setPreviousToken,
  nextAction,
} from "../store/ducks/planItem";
import { HeadCell } from "../models/dataTable";
import { PlanItem } from "../models";
import useApp from "./useApp";
import { GetVariables, PlanItemGetVariables } from "../models/app";
import {
  CreatePlanItemInput,
  ModelSortDirection,
  UpdatePlanItemInput,
} from "../models/GQL_API";
import {
  createPlanItem,
  deletePlanItem,
  updatePlanItem,
} from "../graphql/mutations";
import useArea from "./useArea";
import { setOptions } from "../store/ducks/area";
import { onCreatePlanItem } from "../graphql/subscriptions";

const usePlanItem = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { areasFetch, areasOptions } = useArea("areas", "area");

  // Concept
  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  // Areas
  let selectedArea = useSelector((state: any) => state.areas.selected);
  const selectedAreaConcept = useSelector((state: any) => state.areas.concept);

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );

  const paginationFilter = useSelector(
    (state: any) => state[`${listingName}`]["filter"]
  );

  /* AllByConceptPagination */
  async function fetch(params: ListingVariables) {
    const { areaID, searchText, limit, startIndex, moveForward } = params;

    try {
      let defaultArea = null;
      if (!areaID) {
        let areas = await areasFetch({
          searchText: "",
          startIndex: 0,
          limit: 1000,
          conceptID: selectedConcept,
        });
        let options = await areasOptions(areas);
        dispatch(setOptions(options));
        defaultArea = options[0]?.value;
      }

      const filter: any = {
        deleted: { eq: "0" },
      };

      // if (searchText.length > 0) {
      //   filter.name = { contains: searchText.toLowerCase() };
      // }

      // Data
      let listing: any[] = [];
      let currentNextToken;

      // Pagination
      let requestLimit = limit ? limit : storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;

      // Clear pagination data
      if (limit !== storedLimit || startIndex === 0) {
        requestLimit = limit;
        requestToken = null;
        requestPreviousTokens = [];

        dispatch(changeLimit(limit));
      }

      // Get token from previous tokens
      if (!moveForward) {
        const updatedPreviousTokens = [...requestPreviousTokens];
        updatedPreviousTokens.pop();

        requestToken =
          updatedPreviousTokens.length >= 2
            ? updatedPreviousTokens[updatedPreviousTokens.length - 2]
            : null;
        dispatch(
          setNextToken(updatedPreviousTokens[updatedPreviousTokens.length - 1])
        );
        dispatch(setPreviousToken(updatedPreviousTokens));
      }

      let variables = {
        conceptID: selectedConcept,
        areaIDName: {},
        filter,
        ModelSortDirection: ModelSortDirection.ASC,
        limit: requestLimit,
        nextToken: requestToken,
      };

      // Area Filter
      if (searchText.length > 0) {
        variables.areaIDName = {
          eq: {
            areaID: defaultArea,
            name: searchText.toLowerCase(),
          },
        };
      }

      const ListData: any = await API.graphql<PlanItem>({
        query: planItemByConceptID,
        variables: variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      currentNextToken = ListData.data.planItemByConceptID.nextToken;
      listing = ListData.data.planItemByConceptID.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: ListingVariables) {
    const { limit } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      const ListData: any = await API.graphql<PlanItem>({
        query: listPlanItems,
        variables: { filter, limit, nextToken: nextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = ListData.data.listPlanItems.nextToken;
      const listing = ListData.data.listPlanItems.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAllByConcept(params: ListingVariables) {
    const { limit } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      // $areaIDName: ModelPlanItemByConceptIDCompositeKeyConditionInput;
      const ListData: any = await API.graphql<PlanItem>({
        query: planItemByConceptID,
        variables: {
          conceptID: selectedConcept,
          filter,
          ModelSortDirection: ModelSortDirection.ASC,
          limit,
          nextToken: nextToken,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = ListData.data.planItemByConceptID.nextToken;
      const listing = ListData.data.planItemByConceptID.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: GetVariables) {
    try {
      const { id } = params;

      const planItem: any = await API.graphql<GraphQLQuery<PlanItem>>({
        query: getPlanItem,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      return planItem.data?.getPlanItem;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(id: string, session = false) {
    try {
      const planItem: any = await API.graphql<GraphQLQuery<PlanItem>>({
        query: getPlanItem,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return planItem.data?.getPlanItem;
    } catch (err) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getNames = (params: PlanItemGetVariables) => {
    const { listing, tables } = params;

    if (!tables) {
      const error = new Error("Cannot get plan item names without tables");
      return showError(error);
    }

    let tableName = "";

    if (tables.size > 0) {
      tables.forEach((table: string) => {
        const model = listing.find((model: PlanItem) => model.id === table);

        if (model) tableName += model.name + "-";
      });

      return tableName.slice(0, -1);
    }

    return "";
  };

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    // if (!data.listing) {
    //   const error = new Error("Old plan items cannot be empty");
    //   return showError(error);
    // }

    try {
      const createInput: CreatePlanItemInput = {
        areaID: data.areaID,
        conceptID: selectedConcept,
        xPosition: data.xPosition,
        yPosition: data.yPosition,
        name: data.name,
        lockDays: [],
        isReserved: false,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.table) createInput.table = data.table;
      if (data.object) createInput.object = data.object;
      if (data.bookings) createInput.bookings = data.bookings;

      const model = await API.graphql<PlanItem>({
        query: createPlanItem,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      if (data.listing) {
        dispatch(setListing([...data.listing, model]));
      }

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function createMockPlanItem() {
    try {
      for (let i = 201; i < 301; i++) {
        const name = `${i}`;

        /* AreaID:
         * Liwan: 8f0ed632-1fea-427b-b733-009499801418
         * West Study: 8f0ed632-1fea-427b-b733-006799801418
         * East Study: 8f0ed632-1fea-427b-b733-009499801456
         * FC Dining: 2bd004dc-d15f-4ad2-8a8e-93b104552106
         */

        /* TableID:
         * Liwan: 6679a865-d396-4563-a744-f65691c2349d
         * West Study:6679a865-d396-4563-a744-f65691c2b145
         * East Study: 6679a865-d396-4563-a744-f65691c2b49d
         * FC Dining: 0b40346b-75c0-426a-bd7c-4e8f5a9baa12
         */
        const data = {
          userID: "35398708-81b5-4cb7-b982-776c0d2a75c8",
          userName: "Admin",

          data: {
            areaID: "",
            table: "",
            xPosition: 0,
            yPosition: 0,
            name: name,
            listing: [],
          },
        };

        // Register Admin
        await create(data);
      }
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function update(params: PlanItemUpdateVariables) {
    const { id, data } = params;

    try {
      const original: any = await get({ id });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdatePlanItemInput = {
        id: original.id,

        name: data.name ? data.name.toLowerCase() : original!.name,
        xPosition: data.xPosition ? data.xPosition : original!.xPosition,
        yPosition: data.yPosition ? data.yPosition : original!.yPosition,
        // Booking Fields
        lockDays:
          data.lockDays !== undefined ? data.lockDays : original!.lockDays,
        isReserved: data.isReserved ? data.isReserved : original!.isReserved,
        bookings: data.bookings ? data.bookings : original!.bookings,

        _version: original._version,
      };

      await API.graphql<PlanItem>({
        query: updatePlanItem,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original: any = await get(params);

      if (original) {
        const updateInput: UpdatePlanItemInput = {
          id: original.id,
          deleted: "1",
          _version: original._version,
        };

        await API.graphql<PlanItem>({
          query: updatePlanItem,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: PlanItemBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: PlanItemGetVariables) {
    try {
      const { id, listing } = params;

      await API.graphql<PlanItem>({
        query: deletePlanItem,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  async function exportAll(params: any) {
    try {
      const data = await fetch(params);
      let exportedData: any[] = [];

      for (let planItem of data!) {
        let row: any = { ...planItem };

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  function options(listing: PlanItem[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = PlanItem as any;
  api[`${listingName}CreateSubscription`] = onCreatePlanItem;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchAllByConcept`] = fetchAllByConcept;

  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}CreateMockPlanItem`] = createMockPlanItem;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}GetNames`] = getNames;
  api[`${listingName}ChangeListing`] = (listing: PlanItem[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}ChangeSaveChanges`] = (flag: boolean) =>
    dispatch(setSaveChanges(flag));

  return api;
};

export default usePlanItem;
