// actions
const SET = "planItems/SET";
const SET_SELECTED = "planItems/SET_SELECTED";
const SET_SAVE_CHANGES = "planItems/SET_SAVE_CHANGES";

const SET_FILTER = "planItems/SET_FILTER";
const CHANGE_LIMIT = "planItems/CHANGE_LIMIT";
const NEXT_ACTION = "planItems/NEXT_ACTION";
const SET_NEXT_TOKEN = "planItems/SET_NEXT_TOKEN";
const SET_PREVIOUS_TOKENS = "planItems/SET_PREVIOUS_TOKENS";

const DEFAULT_STATE = {
  listing: [],
  selected: "",
  saveChanges: false,

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
  filter: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_SAVE_CHANGES:
      return Object.assign({}, state, { saveChanges: action.flag });

    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });
    case SET_FILTER:
      return Object.assign({}, state, { filter: action.filter });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });

    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setSaveChanges = (flag: boolean) => ({
  flag,
  type: SET_SAVE_CHANGES,
});

export const setFilter = (filter: any) => ({ filter, type: SET_FILTER });
export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],
  type: CHANGE_LIMIT,
});
export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],
  type: NEXT_ACTION,
});
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});
export const setPreviousToken = (previousTokens: any) => ({
  previousTokens,
  type: SET_PREVIOUS_TOKENS,
});
