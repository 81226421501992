import { Suspense } from "react";
import Backdrop from "@mui/material/Backdrop";

import ContentLoader from "../ContentLoader";
import useApp from "../../../hooks/useApp";
import Box from "@mui/material/Box";
import CreateModal from "../CreateModal";
import DeleteResource from "../DeleteResource";
import GroupSettingModal from "../GroupSettingModal";
import { useSelector } from "react-redux";
import { ModalProps } from "../../../models/app";
import { DATE_PICKER_TYPES, MODAL_TYPES } from "../../../constants/enums";
import PriceModal from "../PriceModal";
import ChoiceModal from "../ChoiceModal";
import ChoiceGroupModal from "../ChoiceGroupModal";

const MainModal: React.FC = () => {
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const modalContent = useSelector((state: any) => state.app.modalContent);
  const modalContentId = useSelector((state: any) => state.app.modalContentId);
  const datePickerModal: ModalProps = useSelector(
    (state: any) => state.app.datePickerModal
  );
  const bookingModal: ModalProps = useSelector(
    (state: any) => state.app.bookingModal
  );
  const editBookingModal: ModalProps = useSelector(
    (state: any) => state.app.editBookingModal
  );
  const guestStatsModal: ModalProps = useSelector(
    (state: any) => state.app.guestStatsModal
  );

  const { hideDeletePopup, changeDatePickerModal, changeGuestStatsModal } =
    useApp();

  const renderModalContent = () => {
    // date picker modal
    if (datePickerModal.open) return datePickerModal.content;
    // booking modal
    if (bookingModal.open) return bookingModal.content;
    // edit booking modal
    if (editBookingModal.open) return editBookingModal.content;

    // guest stats modal
    if (guestStatsModal.open) return guestStatsModal.content;

    if (typeof modalContent === "object") {
      const { slug } = modalContent;

      if (modalContentId === "new") return <CreateModal slug={slug} />;

      if (modalContentId === "groupSettings")
        return (
          <GroupSettingModal
            groupID={slug}
            settingID={modalContent.resourceId}
          />
        );

      if (modalContentId === "PriceModal")
        return (
          <PriceModal menuItemId={slug} priceId={modalContent.resourceId} />
        );

      if (modalContentId === "ChoiceModal")
        return (
          <ChoiceModal
            choiceGroupId={slug}
            choiceId={modalContent.resourceId}
          />
        );

      if (modalContentId === "ChoiceGroupModal")
        return (
          <ChoiceGroupModal
            menuItemId={slug}
            choiceGroupId={modalContent.resourceId}
          />
        );

      if (modalContent.resourceId && modalContentId === "delete")
        return (
          <DeleteResource slug={slug} resourceId={modalContent.resourceId} />
        );
    }

    return modalContent;
  };

  const handleClose = () => {
    if (datePickerModal.open) {
      const modal: ModalProps = {
        open: false,
        id: MODAL_TYPES.DATE_PICKER,
        content: DATE_PICKER_TYPES.RANGE,
      };

      changeDatePickerModal(modal);
    }

    if (guestStatsModal.open) {
      const modal: ModalProps = {
        open: false,
        id: MODAL_TYPES.GUEST_STATS,
        content: null,
      };

      changeGuestStatsModal(modal);
    }

    hideDeletePopup();
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        alignItems: "flex-start",
      }}
      open={
        modalOpen ||
        datePickerModal.open ||
        bookingModal.open ||
        editBookingModal.open ||
        guestStatsModal.open
      }
    >
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          zIndex: 0,
          cursor: "pointer",
        }}
        onClick={handleClose}
      />
      <Suspense fallback={<ContentLoader />}>{renderModalContent()}</Suspense>
    </Backdrop>
  );
};

export default MainModal;
