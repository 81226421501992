import { extractSelectedCheckboxes } from "./../helpers/utils";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch } from "react-redux";
import { getAccount, listAccounts } from "../graphql/queries";
import {
  setLanguage,
  setListing,
  setNextToken,
  setSelected,
} from "../store/ducks/account";
import { Account } from "../models";
import { CreateAccountInput, UpdateAccountInput } from "../models/GQL_API";
import {
  HeadCell,
  Option,
  ListingVariables,
  AccountGetVariables,
  AccountUpdateVariables,
  AccountBulkTrashVariables,
  CreateVariables,
} from "../models/app";
import {
  createAccount,
  deleteAccount,
  updateAccount,
} from "../graphql/mutations";
import useApp from "./useApp";
import { onCreateAccount } from "../graphql/subscriptions";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const url = window.location.hostname;

  async function fetch(params: ListingVariables) {
    const { searchText, limit } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText)
        filter.or = {
          domain: { contains: searchText.toLowerCase() },
          siteTitle: { contains: searchText.toLowerCase() },
          siteAddress: { contains: searchText.toLowerCase() },
        };

      const accountList: any = await API.graphql<Account>({
        query: listAccounts,
        variables: { filter, limit },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = accountList.data.listAccounts.nextToken;
      const listing = accountList.data.listAccounts.items;

      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function byDomainOnline(isAuth: boolean) {
    try {
      const listing: any = await API.graphql({
        query: listAccounts,
        variables: {
          limit: 1000,
          filter: {
            siteAddress: { contains: url },
          },
        },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const account = listing.data.listAccounts.items[0];

      if (account) {
        dispatch(setSelected(account));

        return account;
      }
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(params: AccountGetVariables) {
    const { id, listing } = params;

    try {
      let single: Account | undefined;
      if (listing.length > 0) {
        single = listing.find((resource: any) => resource.id === id);
      } else {
        const listing: any = await API.graphql<Account>({
          query: getAccount,
          variables: { id },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        single = listing.data.getAccount;
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    const languages: string[] = extractSelectedCheckboxes("languages_", data);
    const features: string[] = extractSelectedCheckboxes("features_", data);
    let dynamicStatus = JSON.stringify([
      { name: "pending", accessibleStates: ["canceled", "confirmed"] },
      { name: "confirmed", accessibleStates: [] },
      { name: "canceled", accessibleStates: [] },
    ]);

    try {
      const createInput: CreateAccountInput = {
        domain: data.domain,
        siteTitle: data.siteTitle,
        tagline: data.tagline,
        description: data.description,
        siteAddress: data.siteAddress,
        defaultLanguage: data.defaultLanguage,
        status: "active",
        languages: languages,
        features: features,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
        kpiReportResetFrequency: data.kpiReportResetFrequency,
        agentBarResetFrequency: data.agentBarResetFrequency,
        dynamicStatus: dynamicStatus,
      };

      await API.graphql<Account>({
        query: createAccount,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return `New ${singleName} has been created successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function update(params: AccountUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      let languages: string[] = [];
      let features: string[] = [];
      languages = extractSelectedCheckboxes("languages_", data);
      features = extractSelectedCheckboxes("features_", data);

      if (features.includes("ff60edee-e54a-4958-bb4e-be4251f978db")) {
        console.log("Did not remove the feature!!");
      }

      const updateInput: UpdateAccountInput = {
        id: original.id,
        domain: data.domain ? data.domain : original.domain,
        description: data.description ? data.description : original.description,
        siteAddress: data.siteAddress ? data.siteAddress : original.siteAddress,
        defaultLanguage: data.defaultLanguage
          ? data.defaultLanguage
          : original.defaultLanguage,
        languages: languages ? languages : original.languages,
        features: features ? features : original.features,
        kpiReportResetFrequency: data.kpiReportResetFrequency
          ? data.kpiReportResetFrequency
          : original!.kpiReportResetFrequency,
        agentBarResetFrequency: data.agentBarResetFrequency
          ? data.agentBarResetFrequency
          : original.agentBarResetFrequency,
        dynamicStatus: data.dynamicStatus || "",
        _version: original._version,
      };

      await API.graphql<Account>({
        query: updateAccount,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return `${singleName} has been updated successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function trash(params: AccountGetVariables) {
    try {
      const original = await get(params);

      if (original) {
        const updateInput: UpdateAccountInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Account>({
          query: updateAccount,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(params: AccountBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: AccountGetVariables) {
    const { id, listing } = params;

    try {
      await API.graphql<Account>({
        query: deleteAccount,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  function options(listing: Account[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.siteTitle, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "siteTitle",
      numeric: false,
      disablePadding: false,
      label: "Site Title",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["siteTitle"];

  const api: any = {};

  api[`${listingName}Model`] = Account as any;
  api[`${listingName}CreateSubscription`] = onCreateAccount;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByDomainOnline`] = byDomainOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Account[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ChangeLanguage`] = (language: string) =>
    dispatch(setLanguage(language));

  return api;
};

export default useResource;
