import {
  CreateMainCategoryInput,
  UpdateMainCategoryInput,
} from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { MainCategory } from "../models";
import { createMainCategory, updateMainCategory } from "../graphql/mutations";
import { getMainCategory } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setAllListing,
  setLastIndex,
  setListing,
  setNextToken,
  setPagination,
} from "../store/ducks/mainCategory";
import { getDateFormatted } from "../helpers/utils";
import { onCreateMainCategory } from "../graphql/subscriptions";
import { Languages } from "../constants/enums";
import { getTimeInUAE } from "../helpers/utils";
import useGeneralPagination from "./useGeneralPagination";

const useMainCategory = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const language = useSelector((state: any) => state.accounts.language);

  const session = useSelector((state: any) => state.app.session);
  const mainCategoriesListing = useSelector(
    (state: any) => state.mainCategories.listing
  );
  const mainCategoriesAllListing = useSelector(
    (state: any) => state.mainCategories.allListing
  );

  let paginationListing = useSelector(
    (state: any) => state.mainCategories.pagination
  );
  const { generalFetch, generalFetchAllByConceptID } = useGeneralPagination(
    listingName,
    singleName
  );

  // Fetch with pagination
  async function fetch(params: ListingByConceptVariables) {
    try {
      // const { conceptID, searchText, limit, startIndex, moveForward } = params;
      const listing = await generalFetch(params, "mainCategoryByConceptID");
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  // Fetch without pagination using index
  async function fetchMainCategoryByConceptID(
    params: ListingByConceptVariables
  ) {
    try {
      // const { conceptID, searchText, limit } = params;
      const listing = await generalFetchAllByConceptID(
        params,
        "mainCategoryByConceptID"
      );
      dispatch(setAllListing(listing));

      return listing;

      // Sort them using precedence
      // const sortedListing = listing.sort((a: any, b: any) => {
      //   return a.precedence - b.precedence;
      // });

      // if (
      //   mainCategoriesAllListing.length === 0 ||
      //   conceptID !== mainCategoriesAllListing[0].conceptID
      // ) {
      // } else {
      //   return mainCategoriesAllListing;
      // }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const mainCategory: any = await API.graphql({
        query: getMainCategory,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return mainCategory.data.getMainCategory;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
    multiLanguages = JSON.stringify(multiLanguages);

    try {
      const createInput: CreateMainCategoryInput = {
        name: data.name.toLowerCase().trim(),
        subName: data.subName ? data.subName.toLowerCase() : "",
        precedence: data.precedence ? data.precedence : "0",
        openTime: data.openTime ? data.openTime : null,
        closeTime: data.closeTime ? data.closeTime : null,
        guestView: data.guestView ? data.guestView : false,
        guestOrder: data.guestOrder ? data.guestOrder : false,
        staffOrder: data.staffOrder ? data.staffOrder : false,
        image: data.image ? data.image : null,
        menuPDF: data.menuPDF ? data.menuPDF : null,
        multiLanguages: multiLanguages,
        conceptID: data.conceptID,
        createdAt: getDateFormatted(getTimeInUAE()),
        createdByID: userID,
        createdByName: userName,
      };

      const mainCategory = await API.graphql<GraphQLQuery<MainCategory>>({
        query: createMainCategory,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // Add it to list
      dispatch(setListing([...mainCategoriesAllListing, mainCategory]));

      showConfirm(`New ${singleName} has been created successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));
      return mainCategory;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      let multiLanguages: any = [];
      if (original.multiLanguages) {
        multiLanguages = new Map(JSON.parse(original.multiLanguages));
        if (data.name) {
          multiLanguages.set(`${language}-name`, data.name);
        }
        if (data.subName) {
          multiLanguages.set(`${language}-subName`, data.subName);
        }
        multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
      } else {
        if (data.name) {
          multiLanguages.push([`${language}-name`, data.name]);
        }
        if (data.subName) {
          multiLanguages.push([`${language}-subName`, data.subName]);
        }
        multiLanguages = JSON.stringify(multiLanguages);
      }

      const updateInput: UpdateMainCategoryInput = {
        id: original.id,
        name:
          data.name && language === Languages.ENGLISH
            ? data.name.toLowerCase()
            : original!.name,
        subName:
          data.subName && language === Languages.ENGLISH
            ? data.subName.toLowerCase()
            : "",
        precedence: data.precedence ? data.precedence : original.precedence,
        openTime: data.openTime ? data.openTime : original.openTime,
        closeTime: data.closeTime ? data.closeTime : original.closeTime,
        guestView: data.guestView,
        guestOrder: data.guestOrder,
        staffOrder: data.staffOrder,
        multiLanguages: multiLanguages,
        image: data.image ? data.image : original.image,
        menuPDF: data.menuPDF ? data.menuPDF : original.menuPDF,
        _version: original._version,
      };

      const mainCategory: any = await API.graphql<GraphQLQuery<MainCategory>>({
        query: updateMainCategory,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));
      return mainCategory.data.updateMainCategory;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: any) {
    let original = await get(params);
    const updateInput: UpdateMainCategoryInput = {
      id: original.id,
      deleted: "1",
      _version: original._version,
    };

    const mainCategory: any = await API.graphql<GraphQLQuery<MainCategory>>({
      query: updateMainCategory,
      variables: { input: updateInput },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    showConfirm(`${singleName} has been moved to trash successfully`);

    for (let i = 0; i < mainCategoriesListing.length; i++) {
      if (mainCategoriesListing[i].id === original.id) {
        mainCategoriesListing.splice(i, 1);
        dispatch(setListing(mainCategoriesListing));
        break;
      }
    }
    for (let i = 0; i < paginationListing.length; i++) {
      if (paginationListing[i].id === original.id) {
        paginationListing.splice(i, 1);
        dispatch(setPagination(paginationListing));
        break;
      }
    }
  }

  async function bulkTrash(params: any) {
    const { ids, listing } = params;
    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });
    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: MainCategory[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = MainCategory as any;
  api[`${listingName}CreateSubscription`] = onCreateMainCategory;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByConceptID`] = fetchMainCategoryByConceptID;

  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}ChangeListing`] = (listing: MainCategory[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: MainCategory[]) =>
    dispatch(setAllListing(listing));
  return api;
};

export default useMainCategory;
