import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

interface Props {
  value: any;
  setValue: any;
  trueLabel: string;
  falseLabel: string;
  direction: string;
}

const TrueFalse: React.FC<Props> = ({
  value,
  setValue,
  trueLabel,
  falseLabel,
  direction,
}) => {
  return (
    <RadioGroup
      aria-labelledby="true-false-question"
      value={value}
      onChange={setValue}
      sx={{ flexDirection: direction }}
    >
      <FormControlLabel control={<Radio />} value={"true"} label={trueLabel} />
      <FormControlLabel
        control={<Radio />}
        value={"false"}
        label={falseLabel}
      />
    </RadioGroup>
  );
};

export default TrueFalse;
