import { FormActions, Pages } from "../constants/enums";

export const generalRenderTitle = (slug: string, action: string) => {
  switch (slug) {
    case Pages.RESERVATION_STATUS:
      return "Reservation Status";
    case Pages.PLAN_ITEMS:
      return "Plan Item";
    case Pages.ADMIN_ROLES:
      return "Admin Role";
    case Pages.ADMIN_GROUPS:
      return "Admin Group";
    case Pages.TIME_SLOTS:
      return "Time Slot";
    case Pages.ADMINS:
      return "System Admin";
    case Pages.STAFF_MEMBERS:
      return "Staff Member";
    case Pages.GROUP_SETTINGS:
      return "Group Setting";
    case Pages.CATEGORIES:
      return "Category";
    case Pages.ORDER_STATUSES:
      return "Order Status";
    case Pages.MAIN_CATEGORIES:
      return "Main Category";
    case Pages.SUB_CATEGORIES:
      return "Sub Category";
    case Pages.MENU_ITEMS:
      return "Menu Item";
    case Pages.PREPARATION_AREA:
      return "Service Area";
    case Pages.ONLINE_ORDER:
      return "Online Order";
    case Pages.MEDIA_LIBRARY:
      return action === FormActions.ADD
        ? "Upload New Media"
        : action === FormActions.EDIT
        ? "Edit Media"
        : action === FormActions.DELETE
        ? "permanently media file"
        : "Media Library";

    // For single word slug
    default:
      return slug.slice(0, -1); // To remove 's'
  }
};
