/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://rgtx0d0273.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://lm65ay2axbbbbkyvyyycjowgkm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gqb6x3kmorcl5bxmepo7xnlxoy",
    "aws_cognito_identity_pool_id": "us-east-2:7e98d33a-3acf-4c0d-b589-12a5fe91929a",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_pUeTY1njL",
    "aws_user_pools_web_client_id": "5eslhh48j6eajfc97h021cc6fd",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "etihadairwaysassetsd26b2-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
