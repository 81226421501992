import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

import { openSnackBar } from "../../../store/ducks/app";
import ButtonLoader from "../ButtonLoader";
import useAdmin from "../../../hooks/useAdmin";
import { TimelineActions } from "../../../constants/enums";
import useTimeline from "../../../hooks/useTimeline";

interface Props {
  closeModal: any;
}

const UserChangePassword: React.FC<Props> = ({ closeModal }) => {
  const { changePassword  ,adminsUpdateUserAttrs} = useAdmin("admins", "admin");
  const dispatch = useDispatch();
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const timelinesListing = useSelector((state: any) => state.timelines.listing);
  const { timelinesCreate } = useTimeline("timelines", "timelines");
  const session = useSelector((state: any) => state.app.session);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
  };

  const handleUpdatePassword = async () => {
    setIsUpdating(true);
    if (!password || !newPassword) {
      setIsUpdating(false);
      return dispatch(openSnackBar("All fields are required", "error"));
    }
    await changePassword(password, newPassword);
    if( !session["custom:isFirstTimeLogin"] || session["custom:isFirstTimeLogin"]===1 || session["custom:isFirstTimeLogin"]==="1")
    {
      await adminsUpdateUserAttrs(session.email, false, false , true);

    }
    setIsUpdating(false);

    const data: any = {
      admin:true,
      actionName:TimelineActions.CHANGE_PASSWORD,
      oldStatus:"none",
      newStatus:"none",
      timelinesListing:timelinesListing,
      customerId:session.sub,
    };
    
    await timelinesCreate({ userID: session.sub,userName: session.name,data})

    closeModal();
  };

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 600,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.grey[300],
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{"Change Password"}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={closeModal}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography component={"span"}>Old Password</Typography>
          </Box>
          <TextField
            placeholder={"Old Password"}
            fullWidth
            value={password}
            type={"password"}
            size="small"
            onChange={handlePasswordChange}
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography component={"span"}>New Password</Typography>
          </Box>
          <TextField
            placeholder={"New Password"}
            fullWidth
            value={newPassword}
            type={"password"}
            size="small"
            onChange={handleNewPasswordChange}
          />
        </Box>

        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            sx={{ mr: 2 }}
            variant="contained"
            size="small"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="error"
            size="small"
            onClick={handleUpdatePassword}
            disabled={isUpdating}
            endIcon={isUpdating && <ButtonLoader size={20} />}
          >
            Save Changes
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default UserChangePassword;
