// actions
const SET = "choices/SET";
const SET_SEARCH_TEXT = "choices/SET_SEARCH_TEXT";
const SET_SELECTED = "choices/SET_SELECTED";
const SET_LAST_INDEX = "choices/SET_LAST_INDEX";
const SET_NEXT_TOKEN = "choices/SET_NEXT_TOKEN";
const SET_PAGINATION = "choices/SET_PAGINATION";
const SET_ALL_LISTING = "choices/SET_ALL_LISTING";
const SET_PREVIOUS_TOKENS = "choices/SET_PREVIOUS_TOKENS";

const CHANGE_LIMIT = "choices/CHANGE_LIMIT";
const NEXT_ACTION = "choices/NEXT_ACTION";
const SET_FILTER = "choices/SET_FILTER";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: "",
  pagination: [],
  allListing: [],
  nextToken: null,
  lastIndex: null,
  
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_PAGINATION:
      return Object.assign({}, state, { pagination: action.pagination });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_LAST_INDEX:
      return Object.assign({}, state, { lastIndex: action.id });
    case SET_ALL_LISTING:
      return Object.assign({}, state, { allListing: action.allListing });

    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });
    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });
    case SET_FILTER:
      return Object.assign({}, state, { filter: action.id });

    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
export const setPagination = (pagination: any[]) => ({
  pagination,
  type: SET_PAGINATION,
});
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setLastIndex = (id: number) => ({ id, type: SET_LAST_INDEX });
export const setAllListing = (allListing: any[]) => ({
  allListing,
  type: SET_ALL_LISTING,
});

export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],
  type: CHANGE_LIMIT,
});
export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],
  type: NEXT_ACTION,
});
export const setPreviousToken = (previousTokens: any) => ({
  previousTokens,
  type: SET_PREVIOUS_TOKENS,
});

export const setFilter = (id: any) => ({ id, type: SET_FILTER });
